import fetch from '@/utils/fetch';

export function getOrderEmailList() {
    return fetch({
        url: '/email/getOrderEmailList',
        method: 'post'
    });
}

export function getClientEmailsForOrder(data) {
    return fetch({
        url: '/email/getClientEmailsForOrder',
        method: 'post',
        data: data
    });
}

export function getStandardEmailPreview(data) {
    return fetch({
        url: '/email/sendOrderEmail',
        method: 'post',
        data: data
    });
}

export function sendOrderCustomEmail(data) {
    return fetch({
        url: '/email/sendOrderCustomEmail',
        method: 'post',
        data: data
    });
}

export function notifyInvoiceRemoval(data) {
    return fetch({
        url: '/email/notifyInvoiceRemoval',
        method: 'post',
        data: data
    });
}

export function getOrderContact(data) {
    return fetch({
        url: '/email/getOrderContact',
        method: 'post',
        data: data
    });
}

export function answerOrderContact(data) {
    return fetch({
        url: '/email/answerOrderContact',
        method: 'post',
        data: data
    });
}

export function downloadOrderEmailAttachment(data) {
    return fetch({
        url: '/email/downloadOrderEmailAttachment',
        method: 'post',
        data: data
    });
}