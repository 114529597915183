import actions from './actions';
import gettersFile from './getters';
import mutationsFile from './mutations';
import { make } from 'vuex-pathify';

export const initialState = () => ({
    order: {
        invoices: {
            list: [],
            dialogInsert: false,
        },
        awb: {
            list: [],
            dialogInsert: false,
            dialogLoading: true,
            couriers: {},
            allCouriers: {},
            addresses: [],
            contacts: [],
            counties: [],
            senderCities: [],
            receiverCities: [],
            lockersRegions: [],
            form: {
                manual: 0,
                pickup: false,
                awb_number: '',
                orders_id: 0,
                courier_id: 0,
                sender: {
                    address_id: '',
                    contact_id: '',
                    contact: '',
                    phone: '',
                    email: '',
                    county: '',
                    city: '',
                    address: '',
                    zip: '',
                    locker_loading_id: 0,
                    enable_locker: false,
                },
                receiver: {
                    company: 0,
                    name: '',
                    contact: '',
                    phone: '',
                    county: '',
                    city: '',
                    address: '',
                    locker_delivery_id: 0,
                    enable_locker: false,
                },
                options: {
                    open_package: false,
                    saturday_delivery: false,
                    epod: false,
                },
                courier_account: 0,
                envelopes: 0,
                packages: 1,
                weight: 1,
                repayment: 0,
                insurance: 0,
                awb_format: 'A4',
                service: '',
                comments: '',
                service_type: null,
                parcels: [
                    {
                        type: 1,
                        length: null,
                        width: null,
                        height: null,
                        weight: null,
                        number: 1,
                    },
                ],
            },
            history: {
                dialog: false,
                list: [],
            },
        },
    },
});

const mutations = {
    ...make.mutations(initialState()),
    ...mutationsFile,
};

const getters = {
    ...make.getters(initialState()),
    ...gettersFile,
};

export default function createSalesStore() {
    return {
        namespaced: true,
        state: initialState,
        actions,
        getters,
        mutations,
    };
}
