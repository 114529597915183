import store from '@/store/index';
import {
    getCouriers,
    getCourierInfo,
    generateAwb,
    getCouriersOptions,
    getOrderAwbs,
    downloadAwb,
    getPickupAddresses,
    getOrderAwbsHistory,
    getCourierServices,
    getOrderInvoices,
    addOrderInvoice,
    deleteOrderInvoice,
    downloadOrderInvoice,
    getCourierLockers,
    getLockersRegions,
    getLockerDetails,
} from '@/api/vanzari';

export default {
    GetCouriers: ({ state }, filters) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCouriers(filters);
                if (res.message == null) return reject(new Error());

                if (Object.keys(res.message.couriers).length > 0) {
                    store.set('sales/order@awb.couriers', res.message.couriers);
                }

                if (Object.keys(res.message.all_couriers).length > 0) {
                    store.set('sales/order@awb.allCouriers', res.message.all_couriers);
                }

                if (res.message.addresses && res.message.addresses.length > 0) {
                    store.set('sales/order@awb.addresses', res.message.addresses);
                }

                if (res.message.contacts && res.message.contacts.length > 0) {
                    store.set('sales/order@awb.contacts', res.message.contacts);
                }

                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GenerateAwb: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await generateAwb(state.order.awb.form);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetCouriersOptions: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCouriersOptions(state.order.awb.form);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                console.log('ERROR OPTIONS ', err);
                return reject(err);
            }
        });
    },
    GetCourierServices: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCourierServices(state.order.awb.form);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetCourierInfo: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCourierInfo();
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetOrderAwbs: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getOrderAwbs(data);
                if (res.message == null) return reject(new Error());
                store.set('sales/order@awb.list', res.message.list);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    DownloadAwb: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await downloadAwb(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetPickupAddresses: ({ state, getters }, filters) => {
        return new Promise(async (resolve, reject) => {
            try {
                filters = Object.assign({}, filters);
                const selectedCourierId = state?.order?.awb?.form?.courier_id;
                if (selectedCourierId) {
                    const selectedCourier = state?.order?.awb?.couriers?.[selectedCourierId];
                    if (selectedCourier && ['urgentcargus', 'sameday'].includes(selectedCourier.uid)) {
                        const selectedCourierAccountId = state?.order?.awb?.form?.courier_account;
                        if (selectedCourierAccountId) {
                            filters.courierAccountId = selectedCourierAccountId;
                        }
                    }
                }
                const res = await getPickupAddresses(filters);
                if (res.message == null) return reject(new Error());
                store.set('sales/order@awb.addresses', res.message);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetOrderAwbsHistory: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getOrderAwbsHistory(data);
                if (res.message == null) return reject(new Error());
                store.set('sales/order@awb.history.list', res.message);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetOrderInvoices: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getOrderInvoices(data);
                if (res.message == null) return reject(new Error());
                store.set('sales/order@invoices.list', res.message);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    AddOrderInvoice: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await addOrderInvoice(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    DeleteOrderInvoice: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await deleteOrderInvoice(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    DownloadOrderInvoice: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await downloadOrderInvoice(data);
                if (res.message == null) return reject(new Error());
                return resolve(res.message);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetLockersRegions: ({ state }, { courierId }) => {
        return new Promise(async (resolve, reject) => {
            const currentLockersRegions = store.copy('sales/order@awb.lockersRegions');

            if (currentLockersRegions.some((c) => c.courierId === courierId)) {
                return false;
            }
            try {
                const res = await getLockersRegions({ courierId });
                if (res.message == null) return reject(new Error());
                currentLockersRegions.push({ courierId, lockers: res.message });
                store.set('sales/order@awb.lockersRegions', currentLockersRegions);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    GetCourierLockers: ({ state }, { courierId, regionId }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCourierLockers({ courierId, regionId });
                if (res.message == null) return reject(new Error());
                return resolve(res.message);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetLockerDetails: ({ state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getLockerDetails(data);
                if (res.message == null) return reject(new Error());
                return resolve(res.message);
            } catch (err) {
                return reject(err);
            }
        });
    },
};
