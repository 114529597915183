<template>
    <div class="app-container">
        <el-dialog
            :title="$t('sells.order.storno-order')"
            :visible.sync="stornoOrderdialog"
            width="30%"
            @close="stornoOrderdialog = false"
        >
            <div>
                <div class="sub-title">
                    Prin stornare, contravaloarea comenzii va fi restituita clientului de catre dumneavoastra.
                    Confirmati?
                </div>
            </div>
            <div class="separator"></div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="stornoOrderdialog = false">{{ $t('general.no') }}</el-button>
                <el-button type="primary" @click="stornoOrder">{{ $t('general.yes') }}</el-button>
            </span>
        </el-dialog>

        <template>
            <el-dialog
                :title="$t('sells.order.answerDialog.title')"
                :visible.sync="answerModal"
                width="50%"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false"
            >
                <div class="center">
                    <span style="font-size: 1.5em; font-weight: bold">{{ $t('sells.order.answerDialog.text') }}</span>
                </div>
                <div class="separator"></div>
                <div class="center">
                    <el-button
                        type="success"
                        size="small"
                        @click.native="
                            answerOrderContact(true);
                            answerModal = false;
                        "
                        >{{ $t('general.yes') }}
                    </el-button>
                    <el-button
                        type="danger"
                        size="small"
                        @click.native="
                            answerOrderContact(false);
                            answerModal = false;
                        "
                    >
                        {{ $t('general.no') }}
                    </el-button>
                </div>
            </el-dialog>
        </template>

        <template>
            <el-dialog
                :title="$t('sells.order.cancelRejectDialog.title')"
                :visible.sync="showCancelRejectDialog"
                width="95%"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false"
            >
                <el-input v-model="cancelRejectReason" :placeholder="$t('sells.order.cancelRejectDialog.reason')">
                </el-input>
                <div class="separator"></div>
                <el-button type="danger" size="small" @click.native="rejectCancelRequest">
                    {{ $t('sells.order.cancelRejectDialog.approve') }}
                </el-button>
                <el-button type="primary" size="small" @click.native="showCancelRejectDialog = false">
                    {{ $t('sells.order.cancelRejectDialog.close') }}
                </el-button>
            </el-dialog>
        </template>

        <template>
            <el-dialog
                :title="$t('sells.order.answerDialog.title')"
                :visible.sync="answerModal"
                width="50%"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false"
            >
                <div class="center">
                    <span style="font-size: 1.5em; font-weight: bold">{{ $t('sells.order.answerDialog.text') }}</span>
                </div>
                <div class="separator"></div>
                <div class="center">
                    <el-button
                        type="success"
                        size="small"
                        @click.native="
                            answerOrderContact(true);
                            answerModal = false;
                        "
                        >{{ $t('general.yes') }}
                    </el-button>
                    <el-button
                        type="danger"
                        size="small"
                        @click.native="
                            answerOrderContact(false);
                            answerModal = false;
                        "
                    >
                        {{ $t('general.no') }}
                    </el-button>
                </div>
            </el-dialog>
        </template>

        <template>
            <el-dialog :title="'Ban client'" width="50%" :visible.sync="showUserBanDialog">
                <div class="center">
                    <span style="font-size: 1.5em; font-weight: bold">Specificati motivul banarii</span>
                    <div class="separator"></div>
                    <div class="center">
                        <el-input v-model="motiv"></el-input>
                    </div>
                    <div class="separator"></div>
                    <div class="center">
                        <el-button type="danger" size="small" @click.native="banClient()">Baneaza</el-button>
                        <el-button type="success" size="small" @click.native="showUserBanDialog = false"
                            >Renunta
                        </el-button>
                    </div>
                </div>
            </el-dialog>
        </template>
        <el-card
            class="box-card"
            v-if="typeof orderDetails.cancelRequest !== 'undefined' && orderDetails.cancelRequest !== false"
        >
            <div slot="header" class="clearfix" style="font-weight: bold">
                {{ $t('sells.order.clientCancelTitle') }}
            </div>
            <div>
                <span style="color: firebrick"
                    >{{ $t('sells.order.clientCancelDate')
                    }}<span style="font-weight: bold">{{
                        typeof orderDetails.cancelRequest !== 'undefined' && orderDetails.cancelRequest !== false
                            ? orderDetails.cancelRequest.lastupdate
                            : ''
                    }}</span
                    >{{ $t('sells.order.clientCancelText') }}</span
                >
            </div>
            <div class="separator"></div>
            <div>
                <el-button
                    type="danger"
                    style="width: 49%"
                    v-if="orderDetails.returnedCommission == null && !isCanceled && !isStorno && !isFinished"
                    @click.native="cancelOrder"
                >
                    {{ $t('sells.order.clientCancelButton') }}
                </el-button>
                <el-button
                    type="success"
                    :style="{
                        width:
                            orderDetails.returnedCommission == null && !isCanceled && !isStorno && !isFinished
                                ? '49%'
                                : '100%',
                    }"
                    @click.native="showCancelRejectDialog = true"
                >
                    {{ $t('sells.order.clientCancelReject') }}
                </el-button>
            </div>
        </el-card>
        <div class="separator"></div>
        <el-card
            class="box-card commission-return"
            v-if="orderDetails != null && orderDetails.returnedCommission != null"
        >
            <div slot="header" class="clearfix" style="font-weight: bold">
                {{ $t('sells.order.commissionsReturnTitle') }}
            </div>
            <div v-if="commissionNotReturned" class="cel-color">
                <p>
                    <strong>{{ $t('sells.order.commissionNotReturnedText-2') }}</strong>
                </p>
                <p>{{ $t('sells.order.commissionNotReturnedText-1') }}</p>
            </div>
            <div v-else class="cel-color">
                <strong
                    >{{ $t('sells.order.commissionReturned') }}
                    {{ orderDetails.returnedCommission.id_borderou }}</strong
                >
            </div>
        </el-card>

        <div v-if="!isCanceled && !isStorno && orderDetails.returnedCommission == null">
            <Box :type="'warning'" :active="true" v-if="isConfirmed" style="margin-top: 0">
                <div slot="box-body" style="font-size: 15px">
                    <strong>{{ $t('sells.order.box1') }}</strong>
                </div>
            </Box>
            <el-card>
                <div slot="header" class="clearfix">
                    <span style="font-weight: bold">{{ $t('sells.order.header-1') }} {{ orderId }}</span>
                </div>
                <div v-if="paymentChecked && !confirmedPayment && tobeconfirmed === 2">
                    <span style="font-weight: bolder; color: firebrick; font-size: 2em">{{
                        $t('sells.order.bigtext-5')
                    }}</span>
                    <br />
                    <span style="font-weight: bolder; color: firebrick; font-size: 2em">{{
                        $t('sells.order.bigtext-1')
                    }}</span>
                    <br />
                    <span style="font-weight: bold"
                        >{{ $t('sells.order.bigtext-2') }}
                        <span style="color: firebrick">{{ $t('sells.order.bigtext-3') }}</span>
                        <br />
                        {{ $t('sells.order.bigtext-5') }}
                        <span style="color: firebrick" v-if="hasGracePeriod"
                            ><br />{{ $t('sells.order.bigtext-4') }}</span
                        >
                    </span>
                </div>
                <div v-if="tobeconfirmed == 0">
                    <span style="font-weight: bolder; color: firebrick; font-size: 2em">{{
                        $t('sells.order.bigtext-1')
                    }}</span>
                    <br />
                    <span style="font-weight: bolder">{{ $t('sells.order.bigtext-credius-5') }}</span>
                </div>

                <div v-if="payment_messages.hasMessages">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            {{ $t('sells.order.paymentMessages') }}
                        </div>
                        <div v-for="(msg, i) in payment_messages.messages" :key="i">
                            <span style="color: firebrick; font-weight: bold"> [{{ msg.at }}] {{ msg.msg }} </span>
                        </div>
                    </el-card>
                </div>
            </el-card>

            <div class="separator"></div>

            <el-card class="box-card">
                <el-steps :space="200" :active="activeShortcut" simple class="shortcutStepts">
                    <el-step title="Step 1" icon="el-icon-goods" @click.native="goto('ordersProducts', 1)">
                        <div slot="title">
                            {{ $t('sells.order.title-1') }}
                        </div>
                    </el-step>
                    <el-step title="Step 1" icon="el-icon-printer" @click.native="goto('billing', 2)">
                        <div slot="title">
                            {{ $t('sells.order.title-2') }}
                        </div>
                    </el-step>
                    <el-step title="Step 1" icon="el-icon-message" @click.native="goto('clientMessages', 3)">
                        <div slot="title">
                            {{ $t('sells.order.title-3') }}
                        </div>
                    </el-step>
                </el-steps>
                <el-table :data="[orderSummary]" style="width: 100%" class="summaryTable no-bg-table no-border-table">
                    <el-table-column :label="$t('sells.order.label-0')" width="" v-if="hasOrderEmail">
                        <template slot-scope="scope">
                            <el-tag type="primary"
                                ><a :href="`mailto:${orderEmail}`">{{ orderEmail }}</a></el-tag
                            >
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sells.order.label-1')" width="">
                        <template slot-scope="scope">
                            <el-tag :type="paymentMethodCheckType">{{ paymentMethodCheckText }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sells.order.label-2')" width="">
                        <template slot-scope="scope">
                            <el-tag :type="billStatusCheckType">{{ billStatusCheckText }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('sells.order.label-3')"
                        width=""
                        v-if="paymentMethodCheckText !== 'Numerar sau ramburs'"
                    >
                        <template slot-scope="scope">
                            <el-tag :type="paymentStatusCheckType">{{ paymentStatusCheckText }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sells.order.label-5')" width="">
                        <template slot-scope="scope">
                            <el-tag :type="orderStatusCheckType">{{ orderStatusCheckText }}</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>

            <div class="separator"></div>

            <div class="separator"></div>

            <el-card>
                <div slot="header" class="clearfix">{{ $t('sells.order.header-2') }}</div>
                <div>
                    <el-button type="primary" size="medium" @click="printOrderDetails()"
                        >{{ $t('sells.order.print-order') }}
                    </el-button>
                </div>
            </el-card>

            <div class="separator"></div>

            <el-row :gutter="12" if="hasOrderDetails" class="orderDetailsCards">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card class="box-card" body-style="min-height: 300px">
                        <div slot="header" class="clearfix">
                            <span>{{ $t('sells.order.bill-data') }}</span>
                            <span style="text-align: right; color: firebrick" v-if="billingCompany != ''"> (PJ)</span>
                            <span style="text-align: right; color: darkcyan" v-else> (PF)</span>

                            <span class="float-right">
                                <el-button
                                    type="primary"
                                    v-if="(paymentChecked && confirmedPayment) || paymentMethod == 'Ordin de Plata'"
                                    size="mini"
                                    icon="el-icon-edit"
                                    :disabled="isFinished"
                                    plain
                                    @click="editBillingData()"
                                >
                                    {{ $t('general.edit') }}
                                </el-button>
                            </span>
                        </div>
                        <template v-if="paymentChecked && !confirmedPayment && tobeconfirmed === 2">
                            <div class="middle-message">
                                <div style="font-weight: bolder; color: firebrick; font-size: 2em">
                                    {{ $t('sells.order.bigtext-5') }}
                                </div>
                                <br />
                                <div style="font-weight: bolder; color: firebrick; font-size: 2em">
                                    {{ $t('sells.order.bigtext-1') }}
                                </div>
                                <br />
                            </div>
                            <div>
                                <div class="sub-title">
                                    {{ $t('sells.order.title-4') }}
                                </div>
                                {{ clientName }}
                            </div>
                            <div class="mini-separator"></div>
                            <div>
                                <div class="sub-title">
                                    {{ $t('sells.order.title-10') }}
                                </div>
                                {{ clientTelephone }}
                            </div>
                        </template>
                        <template v-else>
                            <div>
                                <div class="sub-title">
                                    {{ $t('sells.order.title-4') }}
                                </div>
                                {{ clientName }}
                            </div>
                            <div class="mini-separator"></div>
                            <div v-if="billingIBAN !== '-'">
                                <div class="sub-title">
                                    {{ $t('sells.order.title-iban') }}
                                </div>
                                {{ billingIBAN }}
                            </div>
                            <div class="mini-separator"></div>
                            <div v-if="billingBanca !== '-'">
                                <div class="sub-title">
                                    {{ $t('sells.order.title-banca') }}
                                </div>
                                {{ billingBanca }}
                            </div>
                            <div class="mini-separator"></div>
                            <div class="mini-separator"></div>
                            <div v-if="billingCompany != ''">
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-5') }}
                                    </div>
                                    {{ billingCompany }}
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-6') }}
                                    </div>
                                    {{ billingCUI }}
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-j') }} {{ $t('sells.order.title-j-extra') }}
                                    </div>
                                    {{ billingJ }}
                                </div>
                                <div class="mini-separator"></div>
                            </div>
                            <div>
                                <div class="sub-title">
                                    {{ $t('sells.order.title-7') }}
                                </div>
                                <span v-html="clientAddress"></span>
                            </div>
                            <div class="mini-separator"></div>
                            <div>
                                <div class="sub-title">
                                    {{ $t('sells.order.title-8') }}
                                </div>
                                {{ clientCounty }}
                            </div>
                            <div class="mini-separator"></div>
                            <div>
                                <div class="sub-title">
                                    {{ $t('sells.order.title-9') }}
                                </div>
                                {{ clientCity }}
                            </div>
                            <div class="mini-separator"></div>
                            <div>
                                <div class="sub-title">
                                    {{ $t('sells.order.title-10') }}
                                </div>
                                {{ clientTelephone }}
                            </div>
                        </template>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card class="box-card" body-style="min-height: 300px">
                        <div slot="header" class="clearfix" style="position: relative">
                            <span>{{ $t('sells.order.header-3') }}</span>
                            <span class="editDeliveryAddress">
                                <!-- <template v-if="paymentChecked && confirmedPayment"> -->
                                <!--     <template v-if="lockerTypeOrder"> -->
                                <!--         <el-button -->
                                <!--             type="primary" -->
                                <!--             size="mini" -->
                                <!--             icon="el-icon-edit" -->
                                <!--             :disabled="isFinished" -->
                                <!--             plain -->
                                <!--             @click="editLockerLocation = true" -->
                                <!--             >{{ $t('general.edit') }}</el-button -->
                                <!--         > -->
                                <!--     </template> -->
                                <!--     <template v-else> -->
                                <!--         <el-button -->
                                <!--             type="primary" -->
                                <!--             size="mini" -->
                                <!--             icon="el-icon-edit" -->
                                <!--             :disabled="isFinished" -->
                                <!--             plain -->
                                <!--             @click="editDeliveryAddress = true" -->
                                <!--             >{{ $t('general.edit') }}</el-button -->
                                <!--         > -->
                                <!--     </template> -->
                                <!-- </template> -->
                            </span>
                        </div>
                        <template v-if="paymentChecked && !confirmedPayment && tobeconfirmed === 2">
                            <div class="middle-message">
                                <div style="font-weight: bolder; color: firebrick; font-size: 2em">
                                    {{ $t('sells.order.bigtext-5') }}
                                </div>
                                <br />
                                <div style="font-weight: bolder; color: firebrick; font-size: 2em">
                                    {{ $t('sells.order.bigtext-1') }}
                                </div>
                                <br />
                            </div>
                            <div>
                                <div class="sub-title">
                                    {{
                                        !lockerTypeOrder
                                            ? $t('sells.order.title-4')
                                            : $t('sells.orders.pickup_pickup_location')
                                    }}
                                </div>
                                {{ clientDeliveryName }}
                            </div>
                            <div class="mini-separator"></div>
                            <div>
                                <div class="sub-title">
                                    {{ $t('sells.order.title-10') }}
                                </div>
                                {{ clientTelephone }}
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="!lockerTypeOrder">
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-4') }}
                                    </div>
                                    {{ clientDeliveryName }}
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-7') }}
                                    </div>
                                    <span v-html="clientDeliveryAddress"></span>
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-8') }}
                                    </div>
                                    {{ clientDeliveryCity }}
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-9') }}
                                    </div>
                                    {{ clientDeliveryCounty }}
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-10') }}
                                    </div>
                                    {{ clientTelephone }}
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.orders.pickup_pickup_location') }}
                                    </div>
                                    {{ pickupName }}
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-10') }}
                                    </div>
                                    {{ clientTelephone }}
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-7') }}
                                    </div>
                                    <span v-html="pickupAdress"></span>
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-8') }}
                                    </div>
                                    {{ pickupTown }}
                                </div>
                                <div class="mini-separator"></div>
                                <div>
                                    <div class="sub-title">
                                        {{ $t('sells.order.title-9') }}
                                    </div>
                                    {{ pickupRegion }}
                                </div>
                            </template>
                        </template>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <AwbStatus></AwbStatus>
                </el-col>
                <!-- <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-card class="box-card" body-style="min-height: 300px">
                    <div slot="header" class="clearfix">
                        <span>Istoric facturi</span>
                    </div>
                    <div v-if="invoiceHistory">
                        <el-table :data="invoiceHistory" style="width: 100%" height="250" class="invoiceTable center-table no-border-table">
                            <el-table-column prop="nr_fact" label="Numar">
                            </el-table-column>
                            <el-table-column prop="platit" label="Data">
                            </el-table-column>
                            <el-table-column label="Status">
                                <template slot-scope="scope">
                                    <el-tag :type="invoiceStatusType(scope.row)">{{invoiceStatusText(scope.row)}}</el-tag>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-else>
                        Nu ai generat nicio factura pentru aceasta comanda.
                    </div>
                </el-card>
            </el-col> -->
            </el-row>

            <div class="separator"></div>

            <el-card v-if="typeof comments != 'string'" class="box-card" id="clientComments">
                <div slot="header" class="clearfix">
                    <span>{{ $t('sells.order.header-5') }}</span>
                </div>

                <div v-for="(item, i) in comments" :key="i">
                    <span>{{ item.comments }}</span>
                </div>
            </el-card>

            <div class="separator"></div>

            <dd-card
                class="box-card"
                id="orderContact"
                v-if="hasOrderContactMessages"
                :title="$t('sells.order.questions')"
                :collapsed="false"
            >
                <Conversation :messages="orderContact" @send="preAnswerOrderContact" />
            </dd-card>

            <div class="separator"></div>

            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    Blocare clienti
                    <span style="float: right">
                        <el-button
                            type="danger"
                            size="small"
                            icon="user-solid-circle"
                            plain
                            @click.native="showUserBanDialog = true"
                            >{{ $t('sells.order.ban-user') }}</el-button
                        >
                    </span>
                </div>
                <div class="separator"></div>
                {{ orderDetails.blocked_by }}
            </el-card>
            <div class="separator"></div>

            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    {{ $t('sells.orders.toBeDelivered-Heading') }}
                </div>
                <div class="separator"></div>
                <el-date-picker v-model="toBeDelivered" type="date" :placeholder="$t('sells.orders.toBeDelivered')">
                </el-date-picker>
                <el-button type="success" size="small" @click="changeFinishingDate"
                    >{{ $t('sells.orders.update') }}
                </el-button>
            </el-card>

            <template v-if="paymentMethod == 'Ordin de Plata'">
                <div class="separator"></div>

                <el-card class="box-card" :disabled="isFinished">
                    <div slot="header" class="clearfix">
                        <span>Proforma</span>
                        <span style="float: right">
                            <el-button
                                v-if="hasProforma && extProforma > 0"
                                type="primary"
                                size="mini"
                                icon="el-icon-download"
                                plain
                                @click.native="downloadProforma"
                            >
                                {{ $t('sells.order.download-proforma') }}
                            </el-button>

                            <span style="margin-left: 10px"></span>

                            <el-select v-model="extProforma" size="mini" :disabled="hasProforma" filterable>
                                <el-option :label="$t('sells.order.standard-proforma')" :value="0"> </el-option>

                                <el-option
                                    v-for="(billingProvider, index) in billingProviders"
                                    :key="index"
                                    :value="billingProvider.value"
                                    :label="billingProvider.text"
                                >
                                </el-option>
                            </el-select>

                            <span style="margin-left: 10px"></span>

                            <template v-if="!hasProforma">
                                <el-button
                                    type="primary"
                                    size="mini"
                                    icon="el-icon-arrow-right"
                                    plain
                                    @click.native="sendProforma(false)"
                                >
                                    {{ $t('sells.order.send-proforma') }}
                                </el-button>
                            </template>

                            <template v-else>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    icon="el-icon-arrow-right"
                                    plain
                                    @click.native="sendProforma(true)"
                                >
                                    {{ $t('sells.order.resend-proforma') }}
                                </el-button>
                            </template>
                        </span>
                    </div>

                    <Box :type="'info'" :active="true" style="margin-top: 0">
                        <div slot="box-body">
                            <ul class="rawUl">
                                <li v-if="!hasProforma">{{ $t('sells.order.box18') }}</li>
                                <li v-else>{{ $t('sells.order.box19') }}</li>
                            </ul>
                        </div>
                    </Box>

                    <template v-if="hasOrderDetails && extProforma > 0 && hasProforma">
                        <el-row :gutter="12">
                            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                                <div class="sub-title">{{ $t('sells.order.proforma-s') }}</div>
                                <el-input v-model="proforma.serie" :disabled="isFinished || extProforma > 0"></el-input>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                                <div class="sub-title">{{ $t('sells.order.proforma-nr') }}</div>
                                <el-input v-model="proforma.numar" :disabled="isFinished || extProforma > 0"></el-input>
                            </el-col>
                        </el-row>
                    </template>
                </el-card>
            </template>

            <div class="separator"></div>

            <el-card class="box-card" :disabled="isFinished">
                <div slot="header" class="clearfix">
                    {{ $t('sells.order.header-6') }}
                </div>
                <template slot-scope="scope">
                    <div v-if="paymentMethod == 'Ordin de Plata'" style="color: firebrick">
                        {{ $t('sells.order.paymentOrderDisclaimer') }}
                        <div class="separataor"></div>
                        <br />
                    </div>
                    <el-select v-model="paymentMethod" :placeholder="$t('sells.order.ph-1')" size="small">
                        <el-option v-for="(item, key) in filteredPaymentMethods" :key="key" :label="item" :value="item">
                        </el-option>
                    </el-select>
                    <span v-if="paymentMethod == 'Ordin de Plata'"
                        >{{ $t('sells.order.payedSum') }}:
                        <el-input-number v-model="paidSum"></el-input-number>
                    </span>
                    <span>
                        <el-button
                            type="success"
                            size="mini"
                            icon="el-icon-check"
                            :disabled="isFinished"
                            plain
                            @click.native="savePaymentMethod"
                        >
                            {{ $t('sells.order.save-1') }}</el-button
                        >
                    </span>
                    <div v-if="paymentChecked && !confirmedPayment && tobeconfirmed === 2">
                        <br />
                        <span style="font-weight: bolder; color: firebrick; font-size: 2em">{{
                            $t('sells.order.bigtext-5')
                        }}</span>
                        <br />
                        <span style="font-weight: bolder; color: firebrick; font-size: 2em">{{
                            $t('sells.order.bigtext-1')
                        }}</span>
                        <br />
                        <span style="font-weight: bold"
                            >{{ $t('sells.order.bigtext-2') }}
                            <span style="color: firebrick">{{ $t('sells.order.bigtext-3') }}</span>
                        </span>
                    </div>
                    <div v-if="tobeconfirmed == 0">
                        <span style="font-weight: bolder; color: firebrick; font-size: 2em">{{
                            $t('sells.order.bigtext-1')
                        }}</span>
                        <br />
                        <span style="font-weight: bolder">{{ $t('sells.order.bigtext-credius-5') }}</span>
                    </div>
                </template>
            </el-card>

            <!--            <div class="separator"></div>-->
            <!--            <el-card class="box-card" v-if="status !== 26">-->
            <!--                <div>-->
            <!--                    <el-button style="width: 100%;" type="danger" size="mini" @click.native="makeDisputed">{{$t('sells.order.makeDisputed')}}</el-button>-->
            <!--                </div>-->
            <!--            </el-card>-->

            <div class="separator"></div>

            <div v-if="payment_messages.hasMessages">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        {{ $t('sells.order.paymentMessages') }}
                    </div>
                    <div v-for="(msg, i) in payment_messages.messages" :key="i">
                        <span style="color: firebrick; font-weight: bold"> [{{ msg.at }}] {{ msg.msg }} </span>
                    </div>
                </el-card>

                <div class="separator"></div>
            </div>

            <el-card ref="productsTable" class="box-card" id="ordersProducts">
                <div slot="header" class="clearfix">
                    <span>{{ $t('sells.order.header-7') }}</span>
                    <span style="float: right">
                        <el-button
                            type="success"
                            size="mini"
                            icon="el-icon-plus"
                            :disabled="isFinished || orderSummary.paymentMethod.toLowerCase().includes('card')"
                            plain
                            @click.native="productModal = true"
                            >{{ $t('sells.order.add-product') }}</el-button
                        >
                        <el-button
                            type="success"
                            size="mini"
                            icon="el-icon-plus"
                            :disabled="isFinished || orderSummary.paymentMethod.toLowerCase().includes('card')"
                            plain
                            @click.native="trasnportModal = true"
                            >{{ $t('sells.order.add-transporttax') }}</el-button
                        >
                        <el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            :disabled="isFinished"
                            plain
                            @click.native="saveProducts"
                            >{{ $t('sells.order.saveChanges') }}</el-button
                        >
                    </span>
                </div>
                <Box :type="'info'" :active="true" style="margin-top: 0">
                    <div slot="box-body">
                        <ul class="rawUl">
                            <li v-html="$t('sells.order.box2')"></li>
                            <li v-html="$t('sells.order.box3')"></li>
                            <li>
                                <strong>{{ $t('sells.order.box4') }}</strong>
                            </li>
                        </ul>
                    </div>
                </Box>
                <el-table :data="orderProducts" border style="width: 100%" size="mini">
                    <el-table-column prop="products_name" :label="$t('general.name')">
                        <template slot-scope="scope">
                            <a
                                v-if="
                                    scope.row.products_model !== 'taxa_transport' &&
                                    scope.row.products_model !== 'taxa_deschidere_colet'
                                "
                                :href="scope.row.url"
                                target="_blank"
                                >{{ scope.row.products_name }}</a
                            >
                            <span v-else>{{ scope.row.products_name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="products_model" :label="$t('sells.order.product-model')" width="120">
                    </el-table-column>
                    <el-table-column :label="$t('sells.order.price-qty')" width="140">
                        <template slot-scope="scope">
                            <el-input
                                @change="recalculatePrice(scope.$index)"
                                v-model="cloneProducts[scope.$index].final_price"
                                size="small"
                                :disabled="
                                    orderSummary.paymentMethod !== 'Numerar sau ramburs' ||
                                    scope.row.products_model == 'taxa_deschidere_colet' ||
                                    scope.row.products_model == 'taxa_transport' ||
                                    !hasModel(scope.row.products_model) ||
                                    isFinished ||
                                    (typeof scope.row.is_retur !== 'undefined' && parseInt(scope.row.is_retur) === 1)
                                "
                            >
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sells.order.qties')" width="150">
                        <template slot-scope="scope">
                            <el-input-number
                                @change="recalculatePrice(scope.$index)"
                                v-model="cloneProducts[scope.$index].products_quantity"
                                :min="1"
                                size="small"
                                :disabled="
                                    orderSummary.paymentMethod !== 'Numerar sau ramburs' ||
                                    scope.row.products_model == 'taxa_deschidere_colet' ||
                                    scope.row.products_model == 'taxa_transport' ||
                                    !hasModel(scope.row.products_model) ||
                                    isFinished ||
                                    (typeof scope.row.is_retur !== 'undefined' && parseInt(scope.row.is_retur) === 1)
                                "
                            >
                            </el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sells.order.dc')" width="65">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.deschidere_colet"
                                :disabled="
                                    orderSummary.paymentMethod !== 'Numerar sau ramburs' ||
                                    scope.row.products_model == 'taxa_deschidere_colet' ||
                                    scope.row.products_model == 'taxa_transport' ||
                                    !hasModel(scope.row.products_model) ||
                                    isFinished ||
                                    (typeof scope.row.is_retur !== 'undefined' && parseInt(scope.row.is_retur) === 1)
                                "
                                @change="saveProducts"
                            ></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="Total" width="70">
                        <template slot-scope="scope">
                            <span>{{ parseInt(scope.row.total_price) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" width="180" align="center">
                        <template slot-scope="scope">
                            <template
                                v-if="typeof scope.row.is_retur !== 'undefined' && parseInt(scope.row.is_retur) === 1"
                            >
                                <el-popover placement="top" width="600" trigger="click">
                                    <el-table :data="returHistory(scope.row.motiv_retur)" size="mini">
                                        <el-table-column width="100" property="date" :label="$t('sells.order.date')">
                                        </el-table-column>
                                        <el-table-column property="reason" :label="$t('sells.order.reason')">
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            width="100"
                                            property="returnedQty"
                                            :label="$t('sells.order.returned2')"
                                        ></el-table-column>
                                    </el-table>
                                    <el-button slot="reference" type="danger" size="mini" plain>
                                        {{ $t('sells.order.returned') }}
                                        ({{ scope.row.qtyReturned }} b.)
                                    </el-button>
                                </el-popover>
                            </template>
                            <template v-else>
                                <el-select
                                    v-model="cloneProducts[scope.$index].status_livrare"
                                    placeholder="Select"
                                    size="small"
                                    @change="autoSubmitStatus(scope.$index)"
                                    :disabled="
                                        scope.row.products_model == 'taxa_deschidere_colet' ||
                                        scope.row.products_model == 'taxa_transport' ||
                                        !hasModel(scope.row.products_model) ||
                                        isFinished
                                    "
                                >
                                    <el-option
                                        v-for="item in productsStatus"
                                        :key="item.value"
                                        :label="item.text"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </template>
                            <div v-if="typeof scope.row.warning !== 'undefined' && scope.row.warning !== ''">
                                <p>
                                    <span style="color: firebrick"
                                        >Schimbati statusul produsului inainte de finalizare</span
                                    >
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="*D.C" width="65">
                    <template slot-scope="scope">
                        <el-switch v-model="productsDC[scope.row.products_model]" @change="setDC(scope.row.products_model, scope.$index)" :disabled="!hasModel(scope.row.products_model)"></el-switch>
                    </template>
                </el-table-column> -->
                    <el-table-column :label="$t('general.actions')" width="190">
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="mini"
                                icon="el-icon-sort"
                                plain
                                @click.native="openReturDialog(scope.row.products_model)"
                                v-if="
                                    canProductBeReturned(scope.row) &&
                                    !(
                                        scope.row.products_model == 'taxa_deschidere_colet' ||
                                        !hasModel(scope.row.products_model)
                                    )
                                "
                            >
                                {{ $t('sells.order.set-retur') }}
                            </el-button>
                            <el-button
                                type="danger"
                                size="mini"
                                icon="el-icon-trash"
                                plain
                                @click.native="deleteProduct(scope.row.products_model)"
                                v-if="
                                    !(
                                        scope.row.products_model == 'taxa_deschidere_colet' ||
                                        !hasModel(scope.row.products_model) ||
                                        isFinished ||
                                        (typeof scope.row.is_retur !== 'undefined' &&
                                            parseInt(scope.row.is_retur) === 1)
                                    )
                                "
                            >
                                {{ $t('general.delete') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div style="display: block; margin-top: 15px">
                    <span class="tips-top">*{{ $t('sells.order.dc') }} = {{ $t('sells.order.dc2') }}</span>
                    <el-tooltip class="item" effect="dark" :content="$t('sells.order.tooltip-1')" placement="top-start">
                        <span class="totalValue">
                            <i class="el-icon-info"></i> {{ $t('sells.order.total-est') }}:
                            {{ parseFloat(orderTotal).toFixed(2) }} RON
                            <span v-if="hasDeschidereColet"
                                >({{ parseFloat(taxaDeschidereColet.toFixed(2)) }} RON
                                {{ $t('sells.order.open-col') }})</span
                            >
                        </span>
                    </el-tooltip>
                </div>
            </el-card>
            <div class="separator"></div>

            <el-card class="box-card" id="kg" v-if="!isFinished && !isStorno">
                <div slot="header" class="clearfix">
                    <span style="font-weight: bold">{{ $t('sells.order.weight') }}</span>
                </div>
                <el-row :gutter="12" if="hasOrderDetails" class="orderDetailsCards">
                    <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                        <el-input v-model="kg" :disabled="isFinished"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                        <el-button type="success" @click="addWeight">{{ $t('sells.order.addWeight') }}</el-button>
                    </el-col>
                </el-row>
            </el-card>

            <div class="separator"></div>

            <!-- INVOICE START -->
            <Invoice
                v-if="confirmedPayment && billingProvidersLoaded"
                :order.sync="$data"
                :billingProviders.sync="billingProviders"
                @event="createdFunction"
            ></Invoice>

            <!-- <el-card v-if="confirmedPayment" class="box-card" id="billing"> -->
            <!--     <div slot="header" class="clearfix"> -->
            <!--         <span>{{ $t('sells.order.header-8') }}</span> -->
            <!--         <span style="float: right"> -->
            <!--             <el-button -->
            <!--                 type="danger" -->
            <!--                 size="mini" -->
            <!--                 icon="el-icon-error" -->
            <!--                 plain -->
            <!--                 v-if="canBeCanceled && !isFinished" -->
            <!--                 @click.native="removeInvoice" -->
            <!--                 v-show="hasInvoice" -->
            <!--             > -->
            <!--                 {{ $t('sells.order.remove-bill') }} -->
            <!--             </el-button> -->
            <!---->
            <!--             <el-button -->
            <!--                 v-if="hasInvoice && extBill > 0" -->
            <!--                 type="primary" -->
            <!--                 size="mini" -->
            <!--                 icon="el-icon-download" -->
            <!--                 plain -->
            <!--                 @click.native="downloadInvoice" -->
            <!--             > -->
            <!--                 {{ $t('sells.order.download-invoice') }} -->
            <!--             </el-button> -->
            <!---->
            <!--             <span style="margin-left: 10px"></span> -->
            <!---->
            <!--             <el-select v-model="extBill" size="mini" :disabled="hasInvoice" filterable> -->
            <!--                 <el-option :label="$t('sells.order.standard-invoice')" :value="0"> </el-option> -->
            <!---->
            <!--                 <el-option -->
            <!--                     v-for="(billingProvider, index) in billingProviders" -->
            <!--                     :key="index" -->
            <!--                     :value="billingProvider.value" -->
            <!--                     :label="billingProvider.text" -->
            <!--                 > -->
            <!--                 </el-option> -->
            <!--             </el-select> -->
            <!--         </span> -->
            <!--     </div> -->
            <!---->
            <!--     <template v-if="!hasInvoice"> -->
            <!--         <Box :type="'info'" :active="true" style="margin-top: 0"> -->
            <!--             <div slot="box-body"> -->
            <!--                 <ul class="rawUl"> -->
            <!--                     <li v-if="extBill <= 0">{{ $t('sells.order.box5') }}</li> -->
            <!--                     <li v-else>{{ $t('sells.order.box6') }}</li> -->
            <!--                 </ul> -->
            <!--             </div> -->
            <!--         </Box> -->
            <!--     </template> -->
            <!---->
            <!--     <el-row :gutter="12" v-if="hasOrderDetails && (hasInvoice || extBill <= 0)" class="orderDetailsCards"> -->
            <!--         <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"> -->
            <!--             <div class="sub-title">{{ $t('sells.order.bill-s') }}</div> -->
            <!--             <el-input v-model="invoice.serial" :disabled="isFinished || extBill > 0"></el-input> -->
            <!--         </el-col> -->
            <!--         <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8"> -->
            <!--             <div class="sub-title">{{ $t('sells.order.bill-nr') }}</div> -->
            <!--             <el-input v-model="invoice.number" :disabled="isFinished || extBill > 0"></el-input> -->
            <!--         </el-col> -->
            <!--     </el-row> -->
            <!-- </el-card> -->
            <!---->
            <!-- END INVOICE -->
            <div class="separator"></div>

            <Awb :confirmedPayment="confirmedPayment" :order="$data" :preselectedCourierID="preselectedCourierId"></Awb>

            <div class="separator"></div>

            <el-card class="box-card">
                <Box :type="'info'" :active="true" style="margin-top: 0">
                    <div slot="box-body">
                        <ul class="rawUl">
                            <li>
                                {{ $t('sells.order.box14') }}
                            </li>
                            <li>
                                {{ $t('sells.order.box15') }}
                            </li>
                        </ul>
                    </div>
                </Box>
                <el-button
                    type="danger"
                    size="medium"
                    style="width: 49%"
                    icon="el-icon-error"
                    plain
                    v-if="!hasInvoice && canBeCanceled && !isFinished"
                    @click.native="cancelOrder"
                >
                    {{ $t('sells.order.cancel-order') }}
                </el-button>
                <!-- <el-button
                    type="danger"
                    size="medium"
                    style="width: 49%"
                    icon="el-icon-error"
                    v-if="hasInvoice && canBeCanceled && !isFinished"
                    plain
                    @click.native="runStorno"
                >
                    {{ $t('sells.order.storno-order') }}
                </el-button> -->
                <el-button
                    type="success"
                    size="medium"
                    style="width: 49%"
                    icon="el-icon-check"
                    @click.native="finishAll()"
                    v-if="!isFinished && confirmedPayment && hasInvoice"
                    v-loading.fullscreen.lock="fullscreenLoading"
                >
                    {{ $t('sells.order.finish-order') }}
                </el-button>
                <el-button
                    v-else-if="isFinished"
                    :disabled="!canStorno"
                    type="danger"
                    size="medium"
                    style="width: 100%"
                    icon="el-icon-error"
                    plain
                    @click.native="runStorno"
                    >{{ $t('sells.order.storno-order') }}
                </el-button>
            </el-card>

            <div class="separator"></div>

            <el-card class="box-card" id="contactType">
                <div slot="header" class="clearfix">
                    <span style="font-weight: bold">{{ $t('sells.order.header-9') }}</span>
                </div>
                <div>
                    <span>{{ $t('sells.order.client-option') }} </span>
                    <span style="font-weight: bold">{{ mod_contact }}</span>
                </div>
            </el-card>

            <div class="separator"></div>

            <Conversations :oid.sync="orderId"> </Conversations>

            <!-- <el-card class="box-card" id="clientMessages"> -->
            <!--     <div slot="header" class="clearfix"> -->
            <!--         <span>{{ $t('sells.order.client-msgs') }}</span> -->
            <!--         <div style="float: right"> -->
            <!--             <el-button -->
            <!--                 type="success" -->
            <!--                 size="mini" -->
            <!--                 icon="el-icon-message" -->
            <!--                 plain -->
            <!--                 @click.native="sendPersonalEmail" -->
            <!--                 >{{ $t('sells.order.send-msg') }} -->
            <!--             </el-button> -->
            <!--             <el-button -->
            <!--                 type="primary" -->
            <!--                 size="mini" -->
            <!--                 icon="el-icon-edit" -->
            <!--                 plain -->
            <!--                 @click.native="preparePersonalMessage" -->
            <!--             > -->
            <!--                 {{ $t('sells.order.personal-email') }} -->
            <!--             </el-button> -->
            <!--             <el-button -->
            <!--                 type="info" -->
            <!--                 size="mini" -->
            <!--                 icon="el-icon-refresh" -->
            <!--                 plain -->
            <!--                 @click.native="getClientEmailsForOrder" -->
            <!--                 >{{ $t('sells.order.refresh-msg') }} -->
            <!--             </el-button> -->
            <!--         </div> -->
            <!--     </div> -->
            <!--     <Box :type="'info'" :active="true" style="margin-top: 0"> -->
            <!--         <div slot="box-body"> -->
            <!--             <ul class="rawUl"> -->
            <!--                 <li> -->
            <!--                     {{ $t('sells.order.box10') }} -->
            <!--                 </li> -->
            <!--                 <li v-html="$t('sells.order.box11')"></li> -->
            <!--                 <li v-html="$t('sells.order.box12')"></li> -->
            <!--                 <li v-html="$t('sells.order.box13')"></li> -->
            <!--             </ul> -->
            <!--         </div> -->
            <!--     </Box> -->
            <!--     <div class="standardEmailContainer"> -->
            <!--         <div class="sub-title">{{ $t('sells.order.title-15') }}</div> -->
            <!--         <el-select -->
            <!--             v-model="standardEmailVal" -->
            <!--             :placeholder="$t('sells.order.ph-2')" -->
            <!--             @change="handleStandardEmailSelect" -->
            <!--         > -->
            <!--             <el-option -->
            <!--                 v-for="item in standardEmailList" -->
            <!--                 :key="item.id" -->
            <!--                 :label="item.label" -->
            <!--                 :value="item.id" -->
            <!--             > -->
            <!--             </el-option> -->
            <!--         </el-select> -->
            <!--     </div> -->
            <!--     <div class="personalEmailContainer" v-if="email.visible"> -->
            <!--         <div class="separator"></div> -->
            <!--         <div> -->
            <!--             <div class="sub-title">{{ $t('sells.order.subject') }}</div> -->
            <!--             <el-input v-model="email.subject"></el-input> -->
            <!--         </div> -->
            <!--         <div class="separator">{{ $t('sells.order.content') }}</div> -->
            <!--         <div> -->
            <!--             <Editor :text.sync="email.content"></Editor> -->
            <!--         </div> -->
            <!--     </div> -->
            <!--     <div class="separator"></div> -->
            <!--     <div class="clientMessagesContainer"> -->
            <!--         <div class="sub-title">{{ $t('sells.order.conv-client') }}</div> -->
            <!--         <div v-if="clientEmails.length > 0"> -->
            <!--             <el-collapse v-model="collapsedEmails" accordion> -->
            <!--                 <el-collapse-item -->
            <!--                     v-for="(parentEmail, i) in clientEmails" -->
            <!--                     :key="parentEmail.generated + i" -->
            <!--                     :title="parentEmail.subject" -->
            <!--                     :name="i" -->
            <!--                 > -->
            <!--                     <EmailList -->
            <!--                         :cemail="parentEmail" -->
            <!--                         :key="parentEmail.generated + i" -->
            <!--                         :parent="true" -->
            <!--                         :order="orderId" -->
            <!--                     ></EmailList> -->
            <!--                 </el-collapse-item> -->
            <!--             </el-collapse> -->
            <!--         </div> -->
            <!--         <div v-else> -->
            <!--             <span class="font-14">{{ $t('sells.order.no-conv') }}</span> -->
            <!--         </div> -->
            <!--     </div> -->
            <!-- </el-card> -->
            <!-- <el-card class="box-card" id="clientMessagesCancelled">
            <div slot="header" class="clearfix">
                <span>Mesaje client</span>
            </div>
            Conversatiile cu clientul nu mai sunt disponibile deoarece <strong>comanda a fost finalizata</strong>.
        </el-card> -->

            <div class="separator"></div>

            <el-card class="box-card" id="orderObs">
                <div slot="header" class="clearfix">
                    <span>{{ $t('sells.order.order-obs') }}</span>
                </div>

                <!-- <el-button type="primary" size="medium" icon="el-icon-check" plain v-if="orderSummary.paymentStatus || billStatus" @click.native="billingAction(false)">{{billingText}}</el-button>
            <el-button type="warning" size="medium" icon="el-icon-error" plain v-if="billingDeleteText.length > 1 && canBeCanceled" @click.native="billingDelete">{{billingDeleteText}}</el-button> -->

                <!--<el-row :gutter="12" if="hasOrderDetails" class="orderDetailsCards">-->
                <!--<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">-->
                <!--<div class="sub-title">AWB si adresa de ridicare</div>-->
                <!--<el-tooltip class="item" effect="dark" placement="top-start">-->
                <!--<div slot="content">Setarea AWBului si adresei de livrare corecte iti ofera atat tie cat si clientului <br> posibilitatea de a verifica in timp real statusul livrarii</div>-->
                <!--<el-input v-model="awb.num" class="input-with-select addressSelect" :disabled="isFinished">-->
                <!--<el-select v-model="awb.address" slot="append" placeholder="Alege adresa" :disabled="isFinished">-->
                <!--<el-option-group v-for="group in addresses" :key="group.label" :label="group.label">-->
                <!--<el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">-->
                <!--</el-option>-->
                <!--</el-option-group>-->
                <!--</el-select>-->
                <!--</el-input>-->
                <!--</el-tooltip>-->
                <!--&lt;!&ndash; <el-input v-model="awb.num"></el-input> &ndash;&gt;-->
                <!--</el-col>-->
                <!--</el-row>-->
                <!--<quill-editor ref="orderObservationsTextEditor" v-model="orderDetails.observations" :options="editorOption">-->
                <!--</quill-editor>-->
                <el-input
                    type="textarea"
                    style="width: 100%"
                    :rows="4"
                    :placeholder="$t('sells.order.obs') + '...'"
                    v-model="orderDetails.observations"
                >
                </el-input>
                <div class="separator"></div>
                <el-button type="primary" size="medium" icon="el-icon-check" @click.native="addObservations()">
                    {{ $t('sells.order.add-obs') }}
                </el-button>
            </el-card>

            <el-dialog
                :title="$t('sells.order.addProductsTitle')"
                :visible.sync="productModal"
                width="50%"
                @close="resetProductSearch"
            >
                <span>
                    <div class="sub-title">{{ $t('sells.order.search-prod') }}</div>
                    <el-input
                        :placeholder="$t('sells.order.search-prod')"
                        v-model="productSearch"
                        class="input-with-select"
                        @keyup.enter.native="searchProduct"
                    >
                        <el-button slot="append" icon="el-icon-search" @click.native="searchProduct"></el-button>
                    </el-input>
                    <div v-if="searchResult && searchResult.length > 0">
                        <div class="separator"></div>
                        <div class="sub-title">{{ $t('sells.order.search-results') }}</div>
                        <el-table :data="searchResult" stripe style="width: 100%" border size="small">
                            <el-table-column prop="products_name" :label="$t('sells.order.name')"> </el-table-column>
                            <el-table-column
                                prop="products_model"
                                width="180px"
                                :label="$t('sells.order.product-model')"
                            >
                            </el-table-column>
                            <el-table-column :label="$t('general.actions')" width="70px" fixed="right">
                                <template slot-scope="scope">
                                    <el-button
                                        type="primary"
                                        @click="addProduct(scope.$index, searchResult)"
                                        size="small"
                                        icon="el-icon-plus"
                                    ></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="productModal = false" plain>{{ $t('general.close') }}</el-button>
                </span>
            </el-dialog>

            <el-dialog :title="$t('sells.order.addTransportTax')" :visible.sync="trasnportModal" width="50%">
                <span>
                    <label for="transportInput">{{ $t('sells.order.inputTransportTax') }}</label>
                    <el-input v-model="transportTax" id="transportInput"></el-input>
                    <div class="separator"></div>
                    <el-button type="success" style="width: 100%" @click.native="addTransportTax"
                        >{{ $t('general.add') }}
                    </el-button>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="trasnportModal = false" plain>{{ $t('general.close') }}</el-button>
                </span>
            </el-dialog>

            <el-dialog title="Anulare comanda" :visible.sync="cancelOrderPop" width="30%" @close="resetCancelParams">
                <div>
                    <Box :type="'info'" :active="true" style="margin-top: 0">
                        <div slot="box-body">
                            <ul class="rawUl">
                                <li>
                                    {{ $t('sells.order.box16') }}
                                </li>
                            </ul>
                        </div>
                    </Box>

                    <el-form
                        :model="cancelReason.form"
                        :rules="cancelReason.formRules"
                        ref="cancelReasonForm"
                        label-position="top"
                        status-icon
                    >
                        <el-form-item :label="$t('sells.order.reason')" prop="id">
                            <el-select
                                v-model="cancelReason.form.id"
                                :placeholder="$t('sells.order.pick-reason')"
                                class="fullWidth"
                            >
                                <el-option
                                    v-for="item in cancelStatusList"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <template v-if="cancelReason.form.id == 4">
                            <el-form-item label="ID-ul comenzii duplicat" prop="duplicate_id">
                                <el-input
                                    v-model="cancelReason.form.duplicate_id"
                                    placeholder="ID-ul comenzii duplicat"
                                ></el-input>
                            </el-form-item>
                        </template>

                        <template v-if="cancelReason.form.id !== ''">
                            <el-form-item label="Comentarii" prop="comment">
                                <el-input
                                    type="textarea"
                                    v-model="cancelReason.form.comment"
                                    :rows="2"
                                    placeholder="Comentarii"
                                ></el-input>
                            </el-form-item>
                        </template>
                    </el-form>

                    <template v-if="cancelReason.form.id == 6">
                        <Box :active="true" type="warning" style="margin: 0">
                            <div slot="box-body">
                                <ul class="rawUl">
                                    <li>Produsele din comanda vor fi dezactivate automat</li>
                                </ul>
                            </div>
                        </Box>
                    </template>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelOrderPop = false">Anuleaza</el-button>
                    <el-button type="primary" @click="cancelOrderWithReason">Trimite</el-button>
                </span>
            </el-dialog>

            <el-dialog
                title="Eliminare factura"
                :visible.sync="removeInvoiceModal"
                width="30%"
                @close="resetCancelParams"
            >
                <div>
                    <div class="sub-title">Scrie motivul eliminarii facturii</div>
                    <el-input v-model="invoiceRemovalReason"></el-input>
                </div>
                <div class="separator"></div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="removeInvoiceModal = false">{{ $t('general.cancel') }}</el-button>
                    <el-button type="primary" @click="notifyInvoiceRemoval">{{ $t('general.send') }}</el-button>
                </span>
            </el-dialog>

            <el-dialog title="Adresa de livrare" :visible.sync="editDeliveryAddress" width="30%">
                <div>
                    <div class="sub-title">{{ $t('sells.order.name-surname') }}</div>
                    <el-input v-model="deliveryChange.name"></el-input>
                    <div class="mini-separator"></div>
                    <div class="sub-title">{{ $t('sells.order.address') }}</div>
                    <el-input v-model="deliveryChange.address"></el-input>
                    <div class="mini-separator"></div>
                    <div class="sub-title">{{ $t('sells.order.city') }}</div>
                    <el-input v-model="deliveryChange.county"></el-input>
                    <div class="mini-separator"></div>
                    <div class="sub-title">{{ $t('sells.order.county') }}</div>
                    <el-input v-model="deliveryChange.city"></el-input>
                    <div class="mini-separator"></div>
                    <div class="sub-title">{{ $t('sells.order.phone') }}</div>
                    <el-input v-model="deliveryChange.phone"></el-input>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="editDeliveryAddress = false">{{ $t('general.close') }}</el-button>
                    <el-button size="small" type="primary" @click="handleDeliveryAddressChange"
                        >{{ $t('general.save') }}
                    </el-button>
                </span>
            </el-dialog>

            <el-dialog title="Adresa de facturare" :visible.sync="editBillableAddress" width="55%">
                <el-row>
                    <div>
                        <center>
                            <el-radio v-model="billableChange.type" label="1" border>{{
                                $t('sells.order.changebilling-client-pf')
                            }}</el-radio>
                            <el-radio v-model="billableChange.type" label="2" border>{{
                                $t('sells.order.changebilling-client-pj')
                            }}</el-radio>
                        </center>
                    </div>

                    <div class="mini-separator"></div>

                    <div v-if="billableChange.type == '2'">
                        <div class="sub-title">{{ $t('sells.order.title-6') }}</div>
                        <el-input v-model="billableChange.cui">
                            <template slot="append">
                                <el-button
                                    :disabled="billableChange.cui.length < 3 || billableChange.loading_data"
                                    @click="getRemoteCUIData()"
                                    >{{ $t('sells.order.changebilling-client-cui-complete') }}</el-button
                                >
                            </template>
                        </el-input>
                        <div class="mini-separator"></div>

                        <el-row :gutter="20">
                            <el-col :xs="24" :sm="24" :md="12">
                                <div class="sub-title">{{ $t('sells.order.title-5') }}</div>
                                <el-input v-model="billableChange.firma"></el-input>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="12">
                                <div class="sub-title">
                                    {{ $t('sells.order.title-j') }} {{ $t('sells.order.title-j-extra') }}
                                </div>
                                <el-input v-model="billableChange.j"></el-input>
                            </el-col>
                        </el-row>
                        <div class="mini-separator"></div>

                        <el-row :gutter="20">
                            <el-col :xs="24" :sm="24" :md="12">
                                <div class="sub-title">{{ $t('sells.order.title-iban') }}</div>
                                <el-input v-model="billableChange.iban"></el-input>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="12">
                                <div class="sub-title">{{ $t('sells.order.title-banca') }}</div>
                                <el-input v-model="billableChange.banca"></el-input>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="mini-separator"></div>

                    <div>
                        <div class="sub-title">{{ $t('sells.order.title-4') }}</div>
                        <el-input v-model="billableChange.persoana"></el-input>
                        <div class="mini-separator"></div>

                        <el-row :gutter="20">
                            <el-col :xs="24" :sm="24" :md="12">
                                <div class="sub-title">{{ $t('sells.order.county') }}</div>
                                <el-select
                                    v-model="billableChange.judet"
                                    @change="onJudetCHange"
                                    filterable
                                    class="fullWidth"
                                >
                                    <el-option
                                        v-for="judet in listaJudete"
                                        :key="judet"
                                        :label="judet"
                                        :value="judet"
                                    ></el-option>
                                </el-select>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="12">
                                <div class="sub-title">{{ $t('sells.order.city') }}</div>
                                <el-select v-model="billableChange.localitate" filterable class="fullWidth">
                                    <el-option
                                        v-for="(localitate, index) in listaLocalitatiJudet"
                                        :key="index"
                                        :label="localitate"
                                        :value="localitate"
                                    ></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <div class="mini-separator"></div>

                        <div class="sub-title">{{ $t('sells.order.address') }}</div>
                        <el-input v-model="billableChange.adresa"></el-input>
                        <div class="mini-separator"></div>

                        <div class="sub-title">{{ $t('sells.order.phone') }}</div>
                        <vue-tel-input
                            v-model="billableChange.telefonInput"
                            @validate="onPhoneValidate"
                            defaultCountry="ro"
                            :inputOptions="{ showDialCode: true, tabindex: 0 }"
                        ></vue-tel-input>
                    </div>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="editBillableAddress = false">{{ $t('general.close') }}</el-button>
                    <el-button size="small" type="primary" @click="handleBillableAddressChange"
                        >{{ $t('general.save') }}
                    </el-button>
                </span>
            </el-dialog>
        </div>
        <div v-else-if="isCanceled">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>{{ $t('sells.order.order-canceled') }}</span>
                    <el-button
                        style="float: right"
                        type="primary"
                        size="mini"
                        icon="el-icon-refresh"
                        plain
                        @click.native="reactivateOrder"
                        >{{ $t('sells.order.reactivate-order') }}
                    </el-button>
                </div>
                <div>
                    <strong>{{ $t('sells.order.reason') }}: </strong>
                    <span style="color: red">{{ orderDetails.motiv }}</span>
                </div>
            </el-card>

            <div class="separator"></div>

            <el-card>
                <div slot="header" class="clearfix">
                    <span>{{ $t('sells.order.products') }}</span>
                </div>
                <div>
                    <el-table :data="orderProducts" border style="width: 100%" size="mini">
                        <el-table-column prop="products_name" :label="$t('general.name')">
                            <template slot-scope="scope">
                                <a :href="scope.row.url" target="_blank">{{ scope.row.products_name }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="products_model" :label="$t('sells.order.product-model')" width="120">
                        </el-table-column>
                        <el-table-column :label="$t('sells.order.price-qty')" width="140">
                            <template slot-scope="scope">
                                <el-input
                                    @change="recalculatePrice(scope.$index)"
                                    v-model="cloneProducts[scope.$index].final_price"
                                    size="small"
                                    :disabled="
                                        scope.row.products_model == 'taxa_deschidere_colet' ||
                                        scope.row.products_model == 'taxa_transport' ||
                                        !hasModel(scope.row.products_model) ||
                                        isFinished ||
                                        (typeof scope.row.is_retur !== 'undefined' &&
                                            parseInt(scope.row.is_retur) === 1)
                                    "
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('sells.order.qties')" width="150">
                            <template slot-scope="scope">
                                <el-input-number
                                    @change="recalculatePrice(scope.$index)"
                                    v-model="cloneProducts[scope.$index].products_quantity"
                                    :min="1"
                                    size="small"
                                    :disabled="
                                        scope.row.products_model == 'taxa_deschidere_colet' ||
                                        scope.row.products_model == 'taxa_transport' ||
                                        !hasModel(scope.row.products_model) ||
                                        isFinished ||
                                        (typeof scope.row.is_retur !== 'undefined' &&
                                            parseInt(scope.row.is_retur) === 1)
                                    "
                                >
                                </el-input-number>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <div v-else-if="isStorno">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>{{ $t('sells.order.stornoed-order') }}</span>
                    <!-- <el-button style="float:right" type="primary" size="mini" icon="el-icon-refresh" plain @click.native="reactivateOrder">Reactiveaza comanda</el-button> -->
                </div>

                <div>
                    <span>{{ motiv_respingere }}</span>
                </div>
            </el-card>
            <div class="separator"></div>

            <el-card>
                <div slot="header" class="clearfix">
                    <span>{{ $t('sells.order.products') }}</span>
                </div>
                <div>
                    <el-table :data="orderProducts" border style="width: 100%" size="mini">
                        <el-table-column prop="products_name" :label="$t('general.name')">
                            <template slot-scope="scope">
                                <a :href="scope.row.url" target="_blank">{{ scope.row.products_name }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="products_model" :label="$t('sells.order.product-model')" width="120">
                        </el-table-column>
                        <el-table-column :label="$t('sells.order.price-qty')" width="140">
                            <template slot-scope="scope">
                                <el-input
                                    @change="recalculatePrice(scope.$index)"
                                    v-model="cloneProducts[scope.$index].final_price"
                                    size="small"
                                    :disabled="
                                        scope.row.products_model == 'taxa_deschidere_colet' ||
                                        scope.row.products_model == 'taxa_transport' ||
                                        !hasModel(scope.row.products_model) ||
                                        isFinished ||
                                        (typeof scope.row.is_retur !== 'undefined' &&
                                            parseInt(scope.row.is_retur) === 1)
                                    "
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('sells.order.qties')" width="150">
                            <template slot-scope="scope">
                                <el-input-number
                                    @change="recalculatePrice(scope.$index)"
                                    v-model="cloneProducts[scope.$index].products_quantity"
                                    :min="1"
                                    size="small"
                                    :disabled="
                                        scope.row.products_model == 'taxa_deschidere_colet' ||
                                        scope.row.products_model == 'taxa_transport' ||
                                        !hasModel(scope.row.products_model) ||
                                        isFinished ||
                                        (typeof scope.row.is_retur !== 'undefined' &&
                                            parseInt(scope.row.is_retur) === 1)
                                    "
                                >
                                </el-input-number>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>

        <template v-if="isCanceled || isStorno">
            <template v-if="hasOrderContactMessages">
                <div class="separator"></div>
                <dd-card class="box-card" id="orderContact" :title="$t('sells.order.questions')" :collapsed="false">
                    <Conversation :messages="orderContact" @send="preAnswerOrderContact" />
                </dd-card>
            </template>

            <div class="separator"></div>

            <el-card class="box-card" id="clientMessages">
                <div slot="header" class="clearfix">
                    <span>{{ $t('sells.order.client-msgs') }}</span>
                    <div style="float: right">
                        <el-button
                            type="success"
                            size="mini"
                            icon="el-icon-message"
                            plain
                            @click.native="sendPersonalEmail"
                            >{{ $t('sells.order.send-msg') }}
                        </el-button>
                        <el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-edit"
                            plain
                            @click.native="preparePersonalMessage"
                        >
                            {{ $t('sells.order.personal-email') }}
                        </el-button>
                        <el-button
                            type="info"
                            size="mini"
                            icon="el-icon-refresh"
                            plain
                            @click.native="getClientEmailsForOrder"
                            >{{ $t('sells.order.refresh-msg') }}
                        </el-button>
                    </div>
                </div>
                <Box :type="'info'" :active="true" style="margin-top: 0">
                    <div slot="box-body">
                        <ul class="rawUl">
                            <li>
                                {{ $t('sells.order.box10') }}
                            </li>
                            <li v-html="$t('sells.order.box11')"></li>
                            <li v-html="$t('sells.order.box12')"></li>
                            <li v-html="$t('sells.order.box13')"></li>
                        </ul>
                    </div>
                </Box>
                <div class="standardEmailContainer">
                    <div class="sub-title">{{ $t('sells.order.title-15') }}</div>
                    <el-select
                        v-model="standardEmailVal"
                        :placeholder="$t('sells.order.ph-2')"
                        @change="handleStandardEmailSelect"
                    >
                        <el-option
                            v-for="item in standardEmailList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="personalEmailContainer" v-if="email.visible">
                    <div class="separator"></div>
                    <div>
                        <div class="sub-title">{{ $t('sells.order.subject') }}</div>
                        <el-input v-model="email.subject"></el-input>
                    </div>
                    <div class="separator">{{ $t('sells.order.content') }}</div>
                    <div>
                        <Editor :text.sync="email.content"></Editor>
                    </div>
                </div>
                <div class="separator"></div>
                <div class="clientMessagesContainer">
                    <div class="sub-title">{{ $t('sells.order.conv-client') }}</div>
                    <div v-if="clientEmails.length > 0">
                        <el-collapse v-model="collapsedEmails" accordion>
                            <el-collapse-item
                                v-for="(parentEmail, i) in clientEmails"
                                :key="parentEmail.generated + i"
                                :title="parentEmail.subject"
                                :name="i"
                            >
                                <EmailList
                                    :cemail="parentEmail"
                                    :key="parentEmail.generated + i"
                                    :parent="true"
                                    :order="orderId"
                                ></EmailList>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div v-else>
                        <span class="font-14">{{ $t('sells.order.no-conv') }}</span>
                    </div>
                </div>
            </el-card>
        </template>

        <el-dialog title="Retur produs" :visible.sync="returDialog.status" width="30%">
            <div>
                <p class="title">Motiv</p>
                <el-input v-model="returDialog.reason" size="small"> </el-input>
                <p class="title">Cantitate</p>
                <el-input-number v-model="returDialog.qty" :min="1" :max="maxProdutReturQty" size="small">
                </el-input-number>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelRetur">Anuleaza</el-button>
                <el-button type="primary" @click="confirmRetur">Confirma</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const DDCard = () => import('@/components/DDCard');
const Box = () => import('vue-info-box-element');
const EmailList = () => import('@/components/Email');
const Editor = () => import('@/components/Editor');
const AwbStatus = () => import('./comanda/AwbStatus');
const Awb = () => import('./comanda/Awb');
const Conversation = () => import('@/components/Conversation/index.vue');

const Invoice = () => import('./comanda/invoice/Index');
const Conversations = () => import('./comanda/conversations/Index.vue');

import store from '@/store/index';
import has from 'lodash.has';
import isEmpty from 'lodash.isempty';

import { VueTelInput } from 'vue-tel-input';
import { sync, call, get } from 'vuex-pathify';

import { Message } from 'element-ui';
import { EventBus } from '@/utils/Bus.js';
import { _base64ToArrayBuffer, debugPromise, parseTime, decodeHTML } from '@/utils/index.js';
import {
    getOrderDetails,
    getOrderStatusList,
    checkPaymentForOrder,
    addProductsToOrder,
    removeProductsFromOrder,
    saveOrderData,
    billingDelete,
    listCancellingStatuses,
    cancelOrder,
    importInvoice,
    setReadyForDelivery,
    finishOrder,
    reactivateOrder,
    addOrderObservations,
    stornoOrder,
    changeDeliveryAddress,
    changeOrderProductStatus,
    getPaymentMethods,
    changeOrderPaymentMethod,
    printOrder,
    sendProforma,
    returProduct,
    finishOrderCombined,
    addTransportTax,
    addWeightToOrder,
    changeBillableAddress,
    changePickupPoint,
    banClient,
    rejectCancelRequest,
    downloadInvoice,
    downloadProforma,
    generateSmartBillProforma,
    downloadSmartBillProforma,
    deleteSmartBillProforma,
    changeFinishingDate,
    makeOrderDisputed,
} from '@/api/vanzari';

import {
    // getClientEmailsForOrder,
    // getOrderEmailList,
    // getStandardEmailPreview,
    sendOrderCustomEmail,
    notifyInvoiceRemoval,
    getOrderContact,
    answerOrderContact,
} from '@/api/email';

import { getProducts } from '@/api/produse';

import { getBillingData, getDateCUI } from '@/api/cont';

import createSalesStore from '@/store/modules/sales/index.js';

export default {
    components: {
        VueTelInput,
        Box,
        'dd-card': DDCard,
        EmailList,
        Editor,
        AwbStatus,
        Awb,
        Conversation,
        Conversations,
        Invoice,
    },
    props: ['cid'],
    data() {
        return {
            orderId: 0,
            orderDetails: {},
            cloneProducts: [],
            invalidOrder: false,
            orderSummary: {
                paymentMethod: '',
                billStatus: '',
                paymentStatus: '',
                orderStatus: '',
            },
            editorOption: {
                placeholder: this.$t('sells.order.editor-ph') + '...',
            },
            paymentStatus: null,
            statusList: {},
            activeShortcut: -1,
            orderTotal: 0,
            taxaDeschidereColet: 0,
            productsStatus: [
                {
                    value: 0,
                    text: this.$t('sells.order.product-notv'),
                },
                {
                    value: 4,
                    text: this.$t('sells.order.product-notstoc'),
                },
                {
                    value: 5,
                    text: this.$t('sells.order.product-ready'),
                },
            ],
            productsDC: {},
            productModal: false,
            trasnportModal: false,
            productSearch: '',
            searchResult: [],
            standardEmailList: [],
            standardEmailVal: '',
            email: {
                subject: '',
                content: '',
                visible: false,
            },
            clientEmails: [],
            collapsedEmails: [],
            cancelOrderPop: false,
            cancelStatusList: [],
            cancelReason: {
                form: {
                    id: '',
                    comment: '',
                    duplicate_id: '',
                },
                formRules: {
                    id: [
                        {
                            required: true,
                            message: 'Motivul este obligatoriu',
                            trigger: 'change',
                        },
                    ],
                    duplicate_id: [
                        {
                            required: true,
                            message: 'ID-ul comenzii duplicat este obligatoriu',
                            trigger: 'change',
                        },
                    ],
                },
            },
            invoice: {
                serial: '',
                number: '',
            },
            addresses: [],
            editDeliveryAddress: false,
            editBillableAddress: false,
            editLockerLocation: false,
            deliveryChange: {
                name: '',
                address: '',
                county: '',
                city: '',
                phone: '',
            },
            listaLocalitatiJudet: [],
            billableChange: {
                cui: '',
                firma: '',
                j: '',
                type: '1',
                persoana: '',
                adresa: '',
                localitate: '',
                judet: '',
                telefon: '',
                telefonInput: '',
                iban: '',
                banca: '',
                loading_data: false,
            },
            billableChangeBackup: {},
            pickupChange: 0,
            pickupCourierId: 0,
            lockerData: {},
            paymentMethod: '',
            paymentMethods: [],
            fullscreenLoading: false,
            removeInvoiceModal: false,
            invoiceRemovalReason: '',
            paymentChecked: false,
            comments: [],
            motiv_respingere: '',
            mod_contact: '',
            paidSum: 0,
            notPaidMessage: '',
            orderContact: [],
            newAnswerOrderContact: '',
            payment_messages: {
                hasMessages: false,
                messages: [],
            },
            transportTax: 0,
            kg: -1,
            showUserBanDialog: false,
            showCancelRejectDialog: false,
            cancelRejectReason: '',
            motiv: '',
            stornoOrderdialog: false,
            canHaveInsured: false,
            answerModal: false,
            extBill: 0,
            extProforma: 0,
            proforma: {
                serie: '',
                numar: '',
            },
            allowSmartBill: false,
            hasGracePeriod: false,
            tobeconfirmed: 2,
            toBeDelivered: '',
            canCancel: 0,
            status: 0,
            returDialog: {
                status: false,
                reason: '',
                qty: 0,
                model: '',
            },
            unpaidAmount: 0,
            declaredValue: -1,
            billingProviders: [],
            billingProvidersLoaded: false,
        };
    },
    methods: {
        ucfirst(string) {
            const parts = string.toLowerCase().split(' ');

            for (let i = 0; i < parts.length; i++) {
                parts[i] = parts[i][0].toUpperCase() + parts[i].substr(1);
            }

            return parts.join(' ');
        },
        getCities: call('app/GetCitiesList'),
        getLockerDetails: call('sales/GetLockerDetails'),
        onJudetCHange(Judet, Clear = true) {
            if (Clear) this.billableChange.localitate = '';

            this.getCities({
                county: this.billableChange.judet,
            })
                .then((res) => {
                    this.listaLocalitatiJudet = res.message;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCities',
                            params: {
                                county: this.receiverCounty,
                            },
                        },
                    });
                });
        },
        onPhoneValidate(data) {
            if (data.valid) {
                this.billableChange.telefon = data.number;
            } else {
                this.billableChange.telefon = '';
            }
        },
        editBillingData() {
            this.editBillableAddress = true;
            this.billableChange = { ...this.billableChangeBackup };
            this.billableChange.type = this.billingCompany == '' ? '1' : '2';
        },
        productModelShouldSkip(model) {
            return model.startsWith('taxa_');
        },
        cancelRetur() {
            this.returDialog = {
                status: false,
                reason: '',
                qty: 0,
                model: '',
            };
        },
        async confirmRetur() {
            if (this.returDialog.reason === '') {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('Motivul este obligatoriu'),
                });
                return false;
            }
            if (this.returDialog.qty === 0) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('Cantitatea este obligatorie'),
                });
                return false;
            }

            if (this.returStornoOrder) {
                this.stornoOrder();
            } else {
                const res = await returProduct({
                    model: this.returDialog.model,
                    orderId: this.orderId,
                    reason: this.returDialog.reason,
                    qty: this.returDialog.qty,
                });

                if (!has(res.message, 'status') || parseInt(res.message.status) !== 1) return false;
                this.getOrderDetails();
                this.cancelRetur();
            }
        },
        openReturDialog(model) {
            this.returDialog.model = model;
            this.returDialog.status = true;
        },
        async changeFinishingDate() {
            const momentDate = this.$moment(String(this.toBeDelivered));
            if (!momentDate.isValid()) {
                return this.sbMsg({
                    type: 'error',
                    message: 'Data selectata nu este valida',
                });
            }
            try {
                const res = await changeFinishingDate({
                    orders_id: this.orderId,
                    date: momentDate.format('YYYY-MM-DD'),
                });
            } catch (e) {
                console.log(e);
            }
        },
        async getRemoteCUIData() {
            if (this.billableChange.loading_data || this.billableChange.cui.length < 3) return;
            this.billableChange.loading_data = true;

            try {
                const res = await getDateCUI({
                    cui: this.billableChange.cui,
                });

                const dateCUI = res.message;
                if (dateCUI) {
                    this.sbMsg({
                        type: 'info',
                        message: this.$t('sells.order.message-getcui-1'),
                    });

                    if (dateCUI.denumire.length > 0) this.billableChange.firma = dateCUI.denumire;
                    if (dateCUI.adresa.adresa.length > 0) this.billableChange.adresa = dateCUI.adresa.adresa;
                    if (dateCUI.adresa.judet.length > 0) this.billableChange.judet = this.ucfirst(dateCUI.adresa.judet);
                    if (dateCUI.adresa.localitate.length > 0)
                        this.billableChange.localitate = this.ucfirst(dateCUI.adresa.localitate);
                    if (dateCUI.nr_reg_com.length > 0) this.billableChange.j = dateCUI.nr_reg_com;
                } else {
                    this.sbMsg({
                        type: 'warn',
                        message: this.$t('sells.order.message-getcui-2'),
                    });
                }
            } catch (e) {
                this.billableChange.loading_data = false;
                console.log(e);
            }

            this.billableChange.loading_data = false;
        },
        async deleteSmartBillProforma() {
            try {
                const res = await deleteSmartBillProforma({
                    orders_id: this.orderId,
                });

                this.proforma.serie = '';
                this.proforma.numar = '';
            } catch (e) {
                console.log(e);
            }
        },
        async downloadSmartBillProforma() {
            try {
                const res = await downloadSmartBillProforma({
                    orders_id: this.orderId,
                });
                res.message.pdf = unescape(encodeURIComponent(res.message.pdf));
                const text = _base64ToArrayBuffer(res.message.pdf);
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([text], {
                        type: 'application/pdf',
                    })
                );
                a.download = 'cel_mp-proforma-' + this.orderId + '.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch (e) {
                console.log(e);
            }
        },
        async generateSmartBillProforma() {
            try {
                const res = await generateSmartBillProforma({
                    orders_id: this.orderId,
                });

                this.proforma.serie = res.message.series;
                this.proforma.numar = res.message.number;
            } catch (e) {
                console.log(e);
            }
        },
        async downloadInvoice() {
            try {
                const res = await downloadInvoice({
                    orders_id: this.orderId,
                });
                res.message.pdf = unescape(encodeURIComponent(res.message.pdf));
                const text = _base64ToArrayBuffer(res.message.pdf);
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([text], {
                        type: 'application/pdf',
                    })
                );
                a.download = 'cel_mp-factura-' + this.orderId + '.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch (e) {
                console.log(e);
            }
        },
        async downloadProforma() {
            try {
                const res = await downloadProforma({
                    orders_id: this.orderId,
                });
                res.message.pdf = unescape(encodeURIComponent(res.message.pdf));
                const text = _base64ToArrayBuffer(res.message.pdf);
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([text], {
                        type: 'application/pdf',
                    })
                );
                a.download = 'cel_mp-proforma-' + this.orderId + '.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch (e) {
                console.log(e);
            }
        },
        rejectCancelRequest() {
            rejectCancelRequest({
                orders_id: this.orderId,
                reason: this.cancelRejectReason,
            })
                .then((res) => {
                    this.createdFunction();
                    this.showCancelRejectDialog = false;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'rejectCancelRequest',
                            params: {
                                orders_id: this.orderId,
                                reason: this.cancelRejectReason,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        showAnswerModal() {
            this.answerModal = true;
        },
        runStorno() {
            if (this.paymentMethod.indexOf('Online cu card bancar') !== -1) {
                this.stornoOrderdialog = true;
            } else {
                this.stornoOrder();
            }
        },
        banClient() {
            banClient({
                orders_id: this.orderId,
                motiv: this.motiv,
            })
                .then((res) => {
                    this.showUserBanDialog = false;
                    this.createdFunction();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'banClient',
                            params: {
                                orders_id: this.orderId,
                                motiv: this.motiv,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        addTransportTax() {
            addTransportTax({
                oid: this.orderId,
                taxValue: this.transportTax,
            })
                .then((result) => {
                    this.getOrderDetails();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'addTransportTax',
                            params: {
                                oid: this.orderId,
                                taxValue: this.transportTax,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        autoSubmitStatus(index) {
            changeOrderProductStatus({
                orders_products_id: this.cloneProducts[index].orders_products_id,
                status: this.cloneProducts[index].status_livrare,
            })
                .then((res) => {
                    Message.success(this.$t('sells.order.submit-status'));
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'changeOrderProductStatus',
                            params: {
                                orders_products_id: this.cloneProducts[index].orders_products_id,
                                status: this.cloneProducts[index].status_livrare,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        handleBillableAddressChange() {
            if (this.billableChange.type == '2') {
                if (this.billableChange.firma.length < 3) {
                    Message.warning(this.$t('sells.order.error-delivery-7'));
                    return false;
                }

                if (this.billableChange.cui.length < 3) {
                    Message.warning(this.$t('sells.order.error-delivery-6'));
                    return false;
                }

                if (this.billableChange.j.length < 3) {
                    Message.warning(this.$t('sells.order.error-delivery-8'));
                    return false;
                }

                // if (this.billableChange.iban.length < 3) {
                //     Message.warning(this.$t('sells.order.error-delivery-9'));
                //     return false;
                // }

                // if (this.billableChange.banca.length < 3) {
                //     Message.warning(this.$t('sells.order.error-delivery-10'));
                //     return false;
                // }
            }

            if (this.billableChange.persoana.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery'));
                return false;
            }

            if (this.billableChange.adresa.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery-2'));
                return false;
            }
            if (this.billableChange.localitate.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery-3'));
                return false;
            }
            if (this.billableChange.judet.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery-4'));
                return false;
            }
            if (this.billableChange.telefon.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery-5'));
                return false;
            }

            this.billableChange.orders_id = this.orderId;
            changeBillableAddress(this.billableChange)
                .then((res) => {
                    if (!has(res, 'message') || parseInt(res.error) > 0) {
                        Message.error(this.$t('sells.order.address-error'));
                        return false;
                    }
                    this.editBillableAddress = false;
                    this.getOrderDetails();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'changeDeliveryAddress',
                            params: this.billableChange,
                        },
                    });
                });
        },
        selectareLocatiePickup(locker) {
            this.pickupChange = locker.pickupId;
            this.pickupCourierId = locker.courier_id;
            this.lockerData = locker;
        },
        handlePickupPointChange() {
            changePickupPoint({
                orderId: this.orderId,
                pickupId: this.pickupChange,
                courierId: this.pickupCourierId,
            })
                .then((res) => {
                    if (!has(res, 'message') || parseInt(res.error) > 0) {
                        Message.error(this.$t('sells.order.address-error'));
                        return false;
                    }
                    this.editLockerLocation = false;
                    this.getOrderDetails();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'changePickupPoint',
                            params: {
                                orderId: this.orderId,
                                pickupId: this.pickupChange,
                                courierId: this.pickupCourierId,
                            },
                        },
                    });
                });
        },
        handleDeliveryAddressChange() {
            if (this.deliveryChange.name.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery'));
                return false;
            }
            if (this.deliveryChange.address.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery-2'));
                return false;
            }
            if (this.deliveryChange.county.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery-3'));
                return false;
            }
            if (this.deliveryChange.city.length < 3) {
                Message.warning(this.$t('sells.order.error-delivery-4'));
                return false;
            }
            this.deliveryChange.orders_id = this.orderId;
            changeDeliveryAddress(this.deliveryChange)
                .then((res) => {
                    if (!has(res, 'message') || parseInt(res.error) > 0) {
                        Message.error(this.$t('sells.order.address-error'));
                        return false;
                    }
                    this.editDeliveryAddress = false;
                    this.getOrderDetails();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'changeDeliveryAddress',
                            params: this.deliveryChange,
                        },
                    });
                });
        },
        stornoOrder() {
            this.$prompt(this.$t('sells.order.storno-reason'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.send'),
                cancelButtonText: this.$t('general.cancel'),
            })
                .then((value) => {
                    if (value.action !== 'confirm') return false;
                    if (value.value == null || value.value.length < 3) {
                        Message.error(this.$t('sells.order.reason-error'));
                        return false;
                    }
                    stornoOrder({
                        orders_id: this.orderId,
                        reason: value.value,
                    })
                        .then((res) => {
                            if (!has(res, 'message') || parseInt(res.error) > 0) {
                                // Message.error(
                                //   "Nu am reusit sa stornez comanda. Contacteaza departamentul tehnic."
                                // );
                                return false;
                            }
                            if (res.message.registeredToBeStorno == 1) {
                                this.sbMsg({
                                    type: 'success',
                                    message: this.$t('sells.order.order-storno-register'),
                                });
                            } else {
                                this.sbMsg({
                                    type: 'success',
                                    message: this.$t('sells.order.order-storno-done'),
                                });
                            }
                            this.getOrderDetails();
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'stornoOrder',
                                    params: {
                                        orders_id: this.orderId,
                                        reason: value.value,
                                    },
                                },
                            });
                            debugPromise('stornoOrder', e);
                        });
                })
                .catch(() => {
                    return false;
                });
        },
        notifyInvoiceRemoval() {
            notifyInvoiceRemoval({
                orders_id: this.orderId,
                reason: this.invoiceRemovalReason,
            })
                .then((res) => {
                    this.removeInvoiceModal = false;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'removeInvoice',
                            params: {
                                orders_id: this.orderId,
                                reason: this.invoiceRemovalReason,
                            },
                        },
                    });
                    debugPromise('removeInvoice', e);
                });
        },
        removeInvoice() {
            this.removeInvoiceModal = true;
            // return removeInvoice({
            //     orders_id: this.orderId
            // }).then(res => {
            //     this.invoice.serial = ""
            //     this.invoice.number = ""
            //     this.orderDetails.serie = ""
            //     this.orderDetails.nr_fact = ""
            //     this.$forceUpdate()
            // }).catch(e => {
            //     debugPromise('removeInvoice', err)
            // });
        },
        addObservations(callback) {
            store.dispatch('app/ToggleLoading', true);

            return addOrderObservations({
                orders_id: this.orderId,
                observations: this.orderDetails.observations,
            })
                .then((res) => {
                    if ('undefined' != typeof callback) callback();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'addOrderObservations',
                            params: {
                                orders_id: this.orderId,
                                observations: this.orderDetails.observations,
                            },
                        },
                    });
                    debugPromise('addObservations', e);
                    store.dispatch('app/ToggleLoading', false);
                    this.fullscreenLoading = false;
                    return false;
                });
        },
        getOrderDetails() {
            return getOrderDetails({
                order: this.orderId,
            })
                .then((res) => {
                    if ('undefined' == typeof res) return false;

                    this.orderDetails = res.message;
                    this.kg = res.message.kg;
                    this.cloneProducts = res.message.products.map((obj) => {
                        obj.deschidere_colet = parseInt(obj.deschidere_colet) == 1;
                        obj.tva = parseInt(obj.tva);
                        obj.products_quantity = parseInt(obj.products_quantity);
                        obj.status_livrare = parseInt(obj.status_livrare);
                        obj.products_price = parseInt(obj.products_price);
                        obj.final_price = parseInt(obj.final_price);

                        // obj.tva = 19
                        return obj;
                    });

                    this.deliveryChange.name = has(this.orderDetails, 'delivery_name')
                        ? this.orderDetails.delivery_name
                        : '';
                    this.deliveryChange.address = has(this.orderDetails, 'delivery_info')
                        ? this.orderDetails.delivery_info.strada
                        : '';
                    this.deliveryChange.county = has(this.orderDetails, 'delivery_info')
                        ? this.orderDetails.delivery_info.suburbie
                        : '';
                    this.deliveryChange.city = has(this.orderDetails, 'delivery_info')
                        ? this.orderDetails.delivery_info.judet
                        : '';
                    this.deliveryChange.phone = has(this.orderDetails, 'customers_telephone')
                        ? this.orderDetails.customers_telephone
                        : '';

                    this.deliveryChange.name =
                        has(this.orderDetails, 'billing_company') &&
                        String(this.orderDetails.billing_company).trim() != ''
                            ? this.orderDetails.billing_company
                            : this.orderDetails.customers_company;

                    this.billableChange.cui = has(this.orderDetails, 'customers_cui')
                        ? this.orderDetails.customers_cui
                        : '';
                    this.billableChange.firma = has(this.orderDetails, 'billing_company')
                        ? this.orderDetails.billing_company
                        : '';
                    this.billableChange.j = has(this.orderDetails, 'customers_j') ? this.orderDetails.customers_j : '';
                    this.billableChange.persoana = has(this.orderDetails, 'customers_name')
                        ? this.orderDetails.customers_name
                        : '';
                    this.billableChange.adresa = has(this.orderDetails, 'billing_info')
                        ? this.orderDetails.billing_info.strada
                        : '';
                    this.billableChange.localitate = has(this.orderDetails, 'billing_info')
                        ? this.orderDetails.billing_info.suburbie
                        : '';
                    this.billableChange.judet = has(this.orderDetails, 'billing_info')
                        ? this.orderDetails.billing_info.judet
                        : '';
                    this.onJudetCHange(null, false);
                    this.billableChange.telefon = has(this.orderDetails, 'customers_telephone')
                        ? this.orderDetails.customers_telephone
                        : '';
                    this.billableChange.telefonInput =
                        (!this.billableChange.telefon.startsWith('+') ? '+40' : '') + this.billableChange.telefon;
                    this.billableChange.iban = has(this.orderDetails, 'customers_iban')
                        ? this.orderDetails.customers_iban
                        : '';
                    this.billableChange.banca = has(this.orderDetails, 'customers_banca')
                        ? this.orderDetails.customers_banca
                        : '';

                    this.billableChangeBackup = { ...this.billableChange };

                    this.pickupChange = this.orderDetails.delivery_country;
                    this.pickupCourierId = this.orderDetails.delivery_state;

                    this.invoice.serial = this.orderDetails.serie;
                    this.invoice.number = this.orderDetails.nr_fact;
                    this.orderSummary.paymentMethod = this.orderDetails.payment_method;
                    this.orderTotal = this.orderDetails.value;
                    this.declaredValue = parseFloat(this.orderTotal).toFixed(2);
                    this.taxaDeschidereColet = 0;
                    for (const key in res.message.taxa_deschidere_colet) {
                        this.taxaDeschidereColet +=
                            res.message.taxa_deschidere_colet[key].products_price *
                            res.message.taxa_deschidere_colet[key].products_quantity;
                    }

                    this.cloneProducts.forEach((el, i) => {
                        this.recalculatePrice(i);
                    });

                    this.paymentMethod = res.message.payment_method;

                    this.comments = res.message.comments;

                    this.motiv_respingere = res.message.motiv_respingere;

                    switch (parseInt(res.message.mod_contact)) {
                        case 0:
                            this.mod_contact = 'telefon';
                            break;
                        case 1:
                            this.mod_contact = 'e-mail';
                            break;
                        default:
                            this.mod_contact = res.message.mod_contact;
                    }

                    this.paidSum = res.message.platit;

                    if (res.message.payment_messages !== '') {
                        this.payment_messages.hasMessages = true;
                        this.payment_messages.messages = res.message.payment_messages;
                    }

                    // this.extBill = res.message.ext_invoice == 1 ? true : false;
                    if (this.hasInvoice) this.extBill = parseInt(res.message.ext_invoice);
                    if (this.hasProforma) this.extProforma = parseInt(res.message.ext_proforma);

                    this.proforma.serie = res.message.proforma_serie;
                    this.proforma.numar = res.message.proforma_numar;

                    let pDate = new Date(res.message.date_purchased);
                    pDate = pDate.getTime() / 1000 + 2 * 24 * 60 * 60;
                    const today = new Date();
                    this.hasGracePeriod = parseInt(today.getTime() / 1000) < pDate;

                    this.tobeconfirmed = res.message.tobeconfirmed;

                    this.toBeDelivered =
                        null === res.message.de_finalizat || res.message.de_finalizat === '0000-00-00 00:00:00'
                            ? ''
                            : res.message.de_finalizat;

                    this.canCancel = res.message.canCancel;

                    this.status = res.message.status;

                    this.allowSmartBill = res.message.usesSmartBill;

                    return true;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getOrderDetails',
                            params: {
                                order: this.orderId,
                            },
                        },
                    });
                    this.invalidOrder = true;
                    debugPromise('getOrderDetails', e);
                    return false;
                });
        },
        getOrderStatusList() {
            getOrderStatusList()
                .then((res) => {
                    this.statusList = res.message;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getOrderStatusList',
                        },
                    });
                    debugPromise('getOrderStatusList', e);
                });
        },
        // getOrderEmailList() {
        //     this.collapsedEmails = [];
        //     this.standardEmailList = [];
        //     getOrderEmailList()
        //         .then((res) => {
        //             if (typeof res.message === 'undefined') return false;
        //             for (const i in res.message) {
        //                 this.collapsedEmails.push(i);
        //                 this.standardEmailList.push({
        //                     id: i,
        //                     label: res.message[i],
        //                 });
        //             }
        //         })
        //         .catch((e) => {
        //             this.$reportToSentry(e, {
        //                 extra: {
        //                     fn: 'getOrderEmailList',
        //                 },
        //             });
        //             debugPromise('getOrderEmailList', e);
        //         });
        // },
        // getClientEmailsForOrder() {
        //     this.clientEmails = [];
        //     getClientEmailsForOrder({
        //         cmd: this.orderId,
        //     })
        //         .then((res) => {
        //             if (res == null || res.message == null) return false;
        //             for (const i in res.message) {
        //                 this.clientEmails.push(res.message[i]);
        //                 window.debug = this.clientEmails;
        //             }
        //         })
        //         .catch((e) => {
        //             this.$reportToSentry(e, {
        //                 extra: {
        //                     fn: 'getClientEmailsForOrder',
        //                     params: {
        //                         cmd: this.orderId,
        //                     },
        //                 },
        //             });
        //             debugPromise('getClientEmailsForOrder', e);
        //         });
        // },
        checkPaymentForOrder() {
            checkPaymentForOrder({
                cmd: this.orderId,
            })
                .then((res) => {
                    this.paymentChecked = true;
                    if (has(res, 'message') && has(res.message, 'hasPaid')) {
                        this.orderSummary.paymentStatus = res.message.hasPaid;
                        if (!this.orderSummary.paymentStatus && res?.message?.isCash) {
                            this.orderSummary.paymentStatus = true;
                        }
                    }
                    if (res.message.hasPaid == false) {
                        this.notPaidMessage = res.message.message.replace('ron', 'RON');
                    }
                })
                .catch((e) => {
                    this.paymentChecked = true;
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'checkPaymentForOrder',
                            params: {
                                cmd: this.orderId,
                            },
                        },
                    });
                    debugPromise('checkPaymentForOrder', e);
                });
        },
        goto(to, active) {
            const element = document.getElementById(to);
            if (element) {
                element.scrollIntoView(true);
                this.activeShortcut = active;
            }
        },
        saveProducts() {
            this.cloneProducts = this.cloneProducts.map((obj) => {
                obj.deschidere_colet = +obj.deschidere_colet;
                return obj;
            });
            saveOrderData({
                data: this.cloneProducts,
                order: this.orderId,
            })
                .then((res) => {
                    if (has(res.message, 'numUpdated') && res.message.numUpdated > 0) {
                        let message = res.message.numUpdated > 1 ? 'S-au' : 'S-a';
                        message += ' actualizat ' + res.message.numUpdated;
                        message += res.message.numUpdated > 1 ? ' produse' : ' produs';
                        this.sbMsg({
                            type: 'success',
                            message: message,
                        });
                        this.getOrderDetails();
                        // if ('undefined' == typeof callback) this.getOrderDetails()
                    } else {
                        this.sbMsg({
                            type: 'warn',
                            message: this.$t('sells.order.error-product'),
                        });
                    }

                    // if ('undefined' != typeof callback) callback()
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'saveOrderData',
                            params: {
                                data: this.cloneProducts,
                                order: this.orderId,
                            },
                        },
                    });
                    debugPromise('saveOrderData', e);
                    this.fullscreenLoading = false;
                    return false;
                });
        },
        deleteProduct(model) {
            if (!this.productModelShouldSkip(model) && this.deleteActionTotalProducts <= 1) {
                this.$alert(this.$t('sells.order.min-1-delete'), this.$t('general.warning'), {
                    confirmButtonText: this.$t('general.ok'),
                    type: 'error',
                });
                return false;
            }

            const vm = this;
            if (model.length < 1) return false;
            this.$confirm(this.$t('sells.order.delete-confirm'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no'),
                type: 'warning',
            })
                .then(() => {
                    removeProductsFromOrder({
                        order: vm.orderId,
                        data: [model],
                    })
                        .then((res) => {
                            vm.getOrderDetails();
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'removeProductsFromOrder',
                                    params: {
                                        order: vm.orderId,
                                        data: [model],
                                    },
                                },
                            });
                            debugPromise('removeProductsFromOrder', e);
                        });
                })
                .catch((e) => {
                    debugPromise('deleteProduct-confirm', e);
                });
        },
        setDC(model, index) {
            if (model !== '' && !isEmpty(model) && typeof this.productsDC[model] !== 'undefined') {
                this.cloneProducts[index].deschidere_colet = +this.productsDC[model];
            }
        },
        hasModel(model) {
            return (!isEmpty(model) && model.length > 0 && model !== '') || false;
        },
        recalculatePrice(index) {
            let totalValue = 0;
            this.$set(
                this.cloneProducts[index],
                'total_price',
                parseInt(this.cloneProducts[index].products_quantity) * parseInt(this.cloneProducts[index].final_price)
            );
            for (const i in this.cloneProducts) {
                totalValue += parseInt(this.cloneProducts[i].total_price);
            }
            this.orderTotal = totalValue;
        },
        invoiceStatusType(row) {
            return parseInt(row.status) > 0 ? 'success' : 'error';
        },
        invoiceStatusText(row) {
            return parseInt(row.status) > 0
                ? this.$t('sells.order.status-active')
                : this.$t('sells.order.status-inactiva');
        },
        searchProduct() {
            if (this.productSearch < 1) return false;
            this.searchResult = [];
            getProducts({
                search: this.productSearch,
                start: 0,
                limit: 1000,
                forceCount: false,
                includeTransport: true,
            })
                .then((res) => {
                    this.searchResult = res.message.data;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getProducts',
                            params: {
                                search: this.productSearch,
                                start: 0,
                                limit: 1000,
                                forceCount: false,
                                includeTransport: true,
                            },
                        },
                    });
                    debugPromise('getProducts', e);
                });
        },
        addProduct(index, list) {
            if (typeof list[index] === 'undefined') return false;
            addProductsToOrder({
                order: this.orderId,
                data: [list[index].products_model],
            })
                .then((res) => {
                    this.productModal = false;
                    this.getOrderDetails();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'addProductsToOrder',
                            params: {
                                order: this.orderId,
                                data: [list[index].products_model],
                            },
                        },
                    });
                    debugPromise('addProductsToOrder', e);
                });
        },
        resetProductSearch() {
            this.productSearch = '';
            this.searchResult = [];
        },
        // preparePersonalMessage() {
        //     this.email.subject = '';
        //     this.email.content = '';
        //     this.email.visible = !this.email.visible;
        //     this.standardEmailVal = '';
        // },
        // handleStandardEmailSelect(val) {
        //     if (typeof val === 'undefined' || val.length < 1) return false;
        //     getStandardEmailPreview({
        //         cmd: this.orderId,
        //         idEmail: val,
        //     })
        //         .then((res) => {
        //             if (typeof res.message === 'undefined' || !has(res.message, 'subject')) {
        //                 return false;
        //             }
        //             this.email.subject = res.message.subject;
        //             this.email.content = res.message.body;
        //             this.email.visible = true;
        //         })
        //         .catch((e) => {
        //             debugPromise('getStandardEmailPreview', e);
        //         });
        // },
        // sendPersonalEmail() {
        //     if (
        //         !this.email.visible ||
        //         this.email.subject == null ||
        //         this.email.content == null ||
        //         this.email.subject.length < 1 ||
        //         this.email.content.length < 1
        //     ) {
        //         this.sbMsg({
        //             type: 'warn',
        //             message: this.$t('sells.order.error-email'),
        //         });
        //         return false;
        //     }
        //
        //     sendOrderCustomEmail({
        //         cmd: this.orderId,
        //         subject: this.email.subject,
        //         body: this.email.content,
        //         stdEmail: this.standardEmailVal,
        //     })
        //         .then((res) => {
        //             this.preparePersonalMessage();
        //             this.getClientEmailsForOrder();
        //         })
        //         .catch((e) => {
        //             this.$reportToSentry(e, {
        //                 extra: {
        //                     fn: 'sendOrderCustomEmail',
        //                     params: {
        //                         cmd: this.orderId,
        //                         subject: this.email.subject,
        //                         body: this.email.content,
        //                         stdEmail: this.standardEmailVal,
        //                     },
        //                 },
        //             });
        //             debugPromise('sendOrderCustomEmail', e);
        //         });
        // },
        reactivateOrder() {
            reactivateOrder({
                cmd: this.orderId,
            })
                .then((res) => {
                    if (!has(res, 'message')) return false;
                    this.getOrderDetails();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'reactivateOrder',
                            params: {
                                cmd: this.orderId,
                            },
                        },
                    });
                    debugPromise('reactivateOrder', e);
                });
        },
        // billingAction(check) {
        //     let params = {
        //         order: this.orderId
        //     }
        //     if (this.billStatus) {
        //         params.check = check === true
        //         printInvoice(params).then(res => {
        //             switch (parseInt(res.error)) {
        //                 case 1:
        //                     this.$message({
        //                         message: res.message,
        //                         type: 'error',
        //                         duration: 4 * 1000
        //                     })
        //                     return false
        //
        //                 case 3:
        //                     if (confirm(res.message)) {
        //                         this.billingAction(true)
        //                     }
        //                     return false
        //             }
        //
        //             var text = _base64ToArrayBuffer(res.message)
        //             var a = window.document.createElement('a')
        //             a.href = window.URL.createObjectURL(new Blob([text], {
        //                 type: 'application/pdf'
        //             }));
        //             a.download = 'factura-' + this.orderId + '.pdf'
        //             document.body.appendChild(a)
        //             a.click()
        //             document.body.removeChild(a)
        //
        //         }).catch(err => {
        //             console.log('error billingAction: ' + err)
        //         })
        //     }
        //     if (!confirm('Dupa generarea facturii comanda va fi confirmata. Sunteti sigur?')) return false
        //
        //     generateInvoice(params).then(res => {
        //         if (parseInt(res.error) > 0) return false
        //         this.getOrderDetails()
        //         this.confirmOrder()
        //         this.$message({
        //             message: 'Factura a fost generata. Va rugam sa setati seriile produselor.',
        //             type: 'error',
        //             duration: 4 * 1000
        //         })
        //         vm.hightlightSN();
        //     }).catch(err => {
        //         console.log('billingAction - generate: ' + err);
        //     })
        // },
        confirmOrder(callback) {
            store.dispatch('app/ToggleLoading', true);

            setReadyForDelivery({
                orders_id: this.orderId,
            })
                .then((res) => {
                    this.getOrderDetails();
                    if ('undefined' != typeof callback) callback();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'setReadyForDelivery',
                            params: {
                                orders_id: this.orderId,
                            },
                        },
                    });
                    debugPromise('setReadyForDelivery', e);
                    store.dispatch('app/ToggleLoading', false);
                    this.fullscreenLoading = false;
                    return false;
                });

            // this.$confirm('Confirm comanda?', 'Atentie', {
            //     confirmButtonText: 'Da',
            //     cancelButtonText: 'Nu',
            //     type: 'warning'
            // }).then(() => {
            //     setReadyForDelivery({
            //         orders_id: this.orderId
            //     }).then(res => {
            //         this.getOrderDetails()
            //         if("undefined" != typeof callback) {console.log(1);callback();}
            //     }).catch(err => {
            //         debugPromise('setReadyForDelivery', err)
            //         return false;
            //     })
            // }).catch((err) => {
            //     debugPromise('confirmOrder-confirm', err)
            //     return false;
            // })
        },
        finishOrder() {
            store.dispatch('app/ToggleLoading', true);

            finishOrder({
                orders_id: this.orderId,
            })
                .then((res) => {
                    if (!has(res, 'message')) {
                        this.fullscreenLoading = false;
                        return false;
                    }
                    this.getOrderDetails();
                    this.fullscreenLoading = false;
                    return true;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'finishOrder',
                            params: {
                                orders_id: this.orderId,
                            },
                        },
                    });
                    debugPromise('finishOrder', e);
                    this.fullscreenLoading = false;
                    return false;
                });

            // this.$confirm('Sigur finalizez comanda?', 'Atentie', {
            //     confirmButtonText: 'Da',
            //     cancelButtonText: 'Nu',
            //     type: 'warning'
            // }).then(() => {
            //     finishOrder({
            //         orders_id: this.orderId
            //     }).then(res => {
            //         if (!has(res, 'message')) return false
            //         this.getOrderDetails()
            //
            //         return true;
            //     }).catch(err => {
            //         debugPromise('finishOrder', err)
            //         return false;
            //     })
            // }).catch((err) => {
            //     debugPromise('finishOrder-confirm', err)
            //     return false;
            // })
        },
        cancelOrder() {
            if (this.confirmedPayment) {
                if (this.paymentMethod !== 'Numerar sau ramburs') {
                    this.$confirm(
                        this.$t('sells.order.cancelWarningDialog.text'),
                        this.$t('sells.order.cancelWarningDialog.title'),
                        {
                            confirmButtonText: this.$t('sells.order.cancelWarningDialog.ok'),
                            cancelButtonText: this.$t('sells.order.cancelWarningDialog.cancel'),
                        }
                    )
                        .then(() => {
                            listCancellingStatuses()
                                .then((res) => {
                                    this.cancelStatusList = res.message;
                                    this.cancelOrderPop = true;
                                })
                                .catch((e) => {
                                    this.$reportToSentry(e, {
                                        extra: {
                                            fn: 'listCancellingStatuses',
                                        },
                                    });
                                    debugPromise('cancelOrder', e);
                                });
                        })
                        .catch(() => {});
                } else {
                    listCancellingStatuses()
                        .then((res) => {
                            this.cancelStatusList = res.message;
                            this.cancelOrderPop = true;
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'listCancellingStatuses',
                                },
                            });
                            debugPromise('cancelOrder', e);
                        });
                }
            } else {
                if (this.canCancel === 0) {
                    this.$msgbox({
                        title: 'Anulare comanda',
                        message:
                            'Comenzile cu plata neconfirmata nu pot fi anulate in primele 4 ore, deoarece pot aparea modificari ale statusului platii',
                    });
                } else {
                    listCancellingStatuses()
                        .then((res) => {
                            this.cancelStatusList = res.message;
                            this.cancelOrderPop = true;
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'listCancellingStatuses',
                                },
                            });
                            debugPromise('cancelOrder', e);
                        });
                }
            }
        },
        cancelOrderWithReason() {
            this.$refs['cancelReasonForm'].validate((valid) => {
                if (valid) {
                    this.$confirm(this.$t('sells.order.cancel-confirm'), this.$t('general.warning'), {
                        confirmButtonText: this.$t('general.yes'),
                        cancelButtonText: this.$t('general.no'),
                        type: 'warning',
                    })
                        .then(() => {
                            this.$confirm(this.$t('sells.order.cancel-confirm-2'), this.$t('general.warning'), {
                                confirmButtonText: this.$t('general.yes'),
                                cancelButtonText: this.$t('general.no'),
                                type: 'warning',
                            })
                                .then(() => {
                                    let params = {
                                        order: this.orderId,
                                        motiv: this.cancelReason.form.id,
                                        obs: this.cancelReason.form.comment,
                                        duplicate_id: this.cancelReason.form.duplicate_id,
                                    };

                                    cancelOrder(params)
                                        .then((res) => {
                                            this.getOrderDetails();
                                            this.cancelOrderPop = false;
                                            this.resetCancelParams;
                                        })
                                        .catch((e) => {
                                            this.$reportToSentry(e, {
                                                extra: {
                                                    fn: 'cancelOrder',
                                                    params,
                                                },
                                            });
                                            debugPromise('cancelOrderWithReason', e);
                                        });
                                })
                                .catch(() => {
                                    this.sbMsg({
                                        type: 'warn',
                                        message: this.$t('sells.order.cancel-catch'),
                                    });
                                    this.cancelOrderPop = false;
                                    return false;
                                });
                        })
                        .catch(() => {
                            this.sbMsg({
                                type: 'warn',
                                message: this.$t('sells.order.cancel-catch'),
                            });
                            this.cancelOrderPop = false;
                            return false;
                        });
                }
            });
        },
        resetCancelParams() {
            this.cancelReason.form.id = '';
            this.cancelReason.form.comment = '';
            this.cancelReason.form.duplicate_id = '';
        },
        hightlightSN() {},
        billingDelete() {
            // if (!this.billStatus) return false
            if (!confirm(this.$t('sells.order.billing-confirm'))) {
                return false;
            }
            const params = {
                cmd: this.orderId,
            };
            billingDelete(params)
                .then((res) => {
                    this.getOrderDetails();
                    this.cancelOrder();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'billingDelete',
                            params,
                        },
                    });
                    debugPromise('billingDelete', e);
                });
        },
        saveInvoice(callback) {
            if (this.invoice.serial.length < 1 && parseInt(this.extBill) <= 0) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('sells.order.invoice-s'),
                });
                this.fullscreenLoading = false;
                return false;
            }

            if (this.invoice.number.length < 1 && parseInt(this.extBill) <= 0) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('sells.order.invoice-nr'),
                });
                this.fullscreenLoading = false;
                return false;
            }

            // if (this.awb.num.length < 1) {
            //     this.$message({
            //         message: 'AWB-ul este obligatoriu',
            //         type: 'error',
            //         duration: 3 * 1000
            //     })
            //     return false
            // }

            importInvoice({
                orders_id: this.orderId,
                serie: this.invoice.serial,
                nr_fact: this.invoice.number,
            })
                .then((res) => {
                    if (!has(res, 'message')) {
                        this.fullscreenLoading = false;
                        return false;
                    }
                    this.getOrderDetails();

                    if ('undefined' != res.message.notimported && res.message.notimported == 1) {
                        this.sbMsg({
                            type: 'warn',
                            message: this.$t('sells.order.error-import-inv'),
                            timeout: 10000,
                        });
                    }

                    if ('undefined' != typeof callback) callback();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'importInvoice',
                            params: {
                                orders_id: this.orderId,
                                serie: this.invoice.serial,
                                nr_fact: this.invoice.number,
                            },
                        },
                    });
                    debugPromise('importInvoice', e);
                    this.fullscreenLoading = false;
                    return false;
                });
        },
        populatePaymentModes() {
            getPaymentMethods()
                .then((res) => {
                    this.paymentMethods = res.message;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getPaymentMethods',
                        },
                    });
                });
        },

        savePaymentMethod() {
            changeOrderPaymentMethod({
                order_id: this.orderId,
                payment_method: this.paymentMethod,
                paidSum: this.paidSum,
            })
                .then(() => {
                    this.checkPaymentForOrder();
                })
                .catch(() => {
                    // this.orderSummary.paymentStatus = false
                });
        },
        finishAll() {
            this.fullscreenLoading = true;
            this.$confirm(this.$t('sells.orders.confirm-finish'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no'),
                type: 'warning',
            })
                .then(() => {
                    // this.saveProducts(() => {
                    //     this.saveInvoice(() => {
                    //         this.saveAwb(() => {
                    //             this.addObservations(() => {
                    //                 // this.confirmOrder(() => {
                    //                 this.finishOrder()
                    //                 // })
                    //             })
                    //         })
                    //     })
                    // })
                    this.finishOrderCombined();
                })
                .catch(() => {
                    this.fullscreenLoading = false;
                });
        },
        finishOrderCombined() {
            if (this.invoice.serial.length < 1 && parseInt(this.extBill) <= 0) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('sells.order.finish-inv-s'),
                });
                this.fullscreenLoading = false;
                return false;
            }

            if (this.invoice.number.length < 1 && parseInt(this.extBill) <= 0) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('sells.order.finish-inv-nr'),
                });
                this.fullscreenLoading = false;
                return false;
            }

            const data = {
                orders_id: this.orderId,
                // serie: this.invoice.serial,
                // nr_fact: this.invoice.number,
                observations: this.orderDetails.observations,
                // products: this.cloneProducts,
                cmd: this.orderId,
                // billing_id: parseInt(this.extBill),
            };

            finishOrderCombined(data)
                .then((res) => {
                    // if ('undefined' != typeof res) {
                    this.getOrderDetails();
                    // }
                    this.fullscreenLoading = false;
                })
                .catch((e) => {
                    debugPromise('finishOrder', e);
                    this.fullscreenLoading = false;
                    if (e.message !== 'Unele produse nu sunt pregatite de livrare') this.getOrderDetails();
                    if (typeof e.message !== 'undefined' && e.message == 'Unele produse nu sunt pregatite de livrare') {
                        this.highlightUnverified();
                    } else {
                        this.$reportToSentry(e, {
                            extra: {
                                fn: 'finishOrderCombined',
                                params: data,
                            },
                        });
                    }
                });
        },
        highlightUnverified() {
            for (const e in this.orderDetails.products) {
                if (
                    this.orderDetails.products[e].products_model !== 'taxa_transport' &&
                    this.orderDetails.products[e].status_livrare == 0
                ) {
                    this.$set(this.orderDetails.products[e], 'warning', 'warning');
                }
            }

            this.$refs.productsTable.$el.scrollIntoView();
        },
        printOrderDetails() {
            printOrder({
                orders_id: this.orderId,
            })
                .then((res) => {
                    const text = _base64ToArrayBuffer(res.message.pdf);
                    const a = window.document.createElement('a');
                    a.href = window.URL.createObjectURL(
                        new Blob([text], {
                            type: 'application/pdf',
                        })
                    );
                    a.download = 'cel_mp-comanda-' + this.orderId + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'printOrder',
                            params: {
                                orders_id: this.orderId,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        sendProforma(resend) {
            sendProforma({
                orders_id: this.orderId,
                billing_id: this.extProforma,
                resend: resend,
            })
                .then((res) => {
                    if (res.message.success) {
                        this.sbMsg({
                            type: 'success',
                            message: this.$t('sells.order.proforma-sent'),
                        });

                        const text = _base64ToArrayBuffer(res.message.pdf);
                        const a = window.document.createElement('a');
                        a.href = window.URL.createObjectURL(
                            new Blob([text], {
                                type: 'application/pdf',
                            })
                        );
                        a.download = 'cel_mp-proforma-' + this.orderId + '.pdf';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);

                        // Reload order data
                        this.getOrderDetails();
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'sendProforma',
                            params: {
                                orders_id: this.orderId,
                            },
                        },
                    });
                });
        },
        returProduct(model) {
            const prompt = async () => {
                try {
                    const reason = await this.$prompt(this.$t('sells.order.retur-prompt'), '', {
                        confirmButtonText: this.$t('general.send'),
                        cancelButtonText: this.$t('general.cancel'),
                    });
                    if (typeof reason.value === 'undefined' || reason.value === '' || reason.value == null) {
                        Message.error(this.$t('sells.order.error-retur-reason'));
                        return false;
                    }

                    const res = await returProduct({
                        model,
                        orderId: this.orderId,
                        reason: reason.value,
                    });

                    if (!has(res.message, 'status') || parseInt(res.message.status) !== 1) return false;
                    this.getOrderDetails();
                } catch (err) {
                    return false;
                }
            };
            prompt();
        },
        showReturSummary(index) {
            console.log(this.orderProducts[index]);
        },
        returHistory(data) {
            if (typeof data === 'undefined') return [];
            try {
                data = JSON.parse(data);
                return data.map((item) => {
                    item.date = parseTime(item.date, '{d}-{m}-{y}');
                    return item;
                });
            } catch (err) {
                return [];
            }
        },
        getOrderContact() {
            getOrderContact({
                orders_id: this.orderId,
            })
                .then((res) => {
                    this.orderContact = [];

                    for (const e in res.message) {
                        for (const i in res.message[e]) {
                            this.orderContact.push({
                                name: res.message[e][i].type == 0 ? res.message[e][i].client : '',
                                message: res.message[e][i].message,
                                fromClient: !res.message[e][i].type ? 1 : 0,
                                date: res.message[e][i].date,
                                id: 'undefined' !== typeof res.message[e][i].id ? res.message[e][i].id : -1,
                            });
                        }
                    }

                    // Sort ascending by date
                    this.orderContact.sort(function (a, b) {
                        if (a.date > b.date) return 1;
                        else if (a.date < b.date) return -1;
                        else return 0;
                    });
                    // if ('object' != typeof res.message) this.orderContact = res.message.replace('\n', '<br>')
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getOrderContact',
                            params: {
                                orders_id: this.orderId,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        preAnswerOrderContact(message) {
            this.newAnswerOrderContact = message;
            this.showAnswerModal();
        },
        answerOrderContact(solved) {
            let id = 0;
            for (const i in this.orderContact) {
                if (this.orderContact[i].id != -1) id = this.orderContact[i].id;
            }
            // for (const item in this.orderContact[key]) {
            //     if (this.orderContact[key][item].type == 0) id = this.orderContact[key][item].id
            // }

            answerOrderContact({
                id: id,
                answer: this.newAnswerOrderContact,
                solved: solved ? 1 : 0,
            })
                .then((res) => {
                    this.getOrderContact();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'answerOrderContact',
                            params: {
                                id: id,
                                answer: this.newAnswerOrderContact,
                                solved: solved ? 1 : 0,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        addWeight() {
            addWeightToOrder({
                orders_id: this.orderId,
                weight: this.kg,
            })
                .then((res) => {})
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'addWeightToOrder',
                            params: {
                                orders_id: this.orderId,
                                weight: this.kg,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        createdFunction() {
            this.orderId = this.cid;
            if (this.orderId === 0 || typeof this.orderId === 'undefined') {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('sells.order.orderid-check'),
                });
                return false;
            }

            this.getOrderDetails()
                .then(async (res) => {
                    // If error, then redirect to all orders page
                    if (res == false) {
                        this.$router
                            .push({
                                path: '/vanzari/comenzi',
                            })
                            .catch((e) => {
                                console.log('router error', e);
                            });

                        return false;
                    }

                    // If successful, continue
                    this.getOrderStatusList();
                    // this.getOrderEmailList();
                    // this.getClientEmailsForOrder();
                    this.checkPaymentForOrder();

                    this.populatePaymentModes();
                    if (this.orderDetails.pickup_locker != 0) {
                        this.lockerData = await this.getLockerData();
                    }

                    this.getOrderContact();
                    this.getBillingData();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getOrderDetails',
                        },
                    });
                    debugPromise('getOrderDetails', e);
                });

            const vm = this;
            // EventBus.$on('replySent', (res) => {
            //     if (res) vm.getClientEmailsForOrder();
            // });
            EventBus.$on('invoiceEvent', () => {
                vm.getOrderDetails();
            });
            EventBus.$on('billingProviderChanged', (val) => {
                vm.extBill = val;
            });
        },
        async getLockerData() {
            return this.getLockerDetails({ lockerId: this.orderDetails.delivery_country });
        },
        makeDisputed() {
            makeOrderDisputed({
                order_id: this.orderId,
            })
                .then((res) => {
                    this.status = 26;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'makeOrderDisputed',
                            params: {
                                order_id: this.orderId,
                            },
                        },
                    });
                });
        },
        canProductBeReturned(product) {
            return (
                typeof this.orderDetails !== 'undefined' &&
                this.orderDetails.data_factura !== '1970-01-01 00:00:00' &&
                new Date().getTime() < new Date(this.orderDetails.data_factura).getTime() + 28 * 24 * 60 * 60 * 1000 &&
                parseInt(product.products_quantity) > parseInt(product.qtyReturned)
            );
        },
        getBillingData() {
            getBillingData().then((res) => {
                if (res.message && Object.keys(res.message).length > 0) {
                    for (let key in res.message) {
                        let provider = res.message[key];
                        if (provider.active) {
                            this.billingProviders.push({
                                value: provider.billing_id,
                                text: this.$t('sales.billing.' + key),
                            });
                        }
                    }

                    // Set first billing provider as default
                    if (this.billingProviders.length > 0) {
                        if (!this.hasInvoice) this.extBill = this.billingProviders[0].value;

                        if (this.paymentMethod == 'Ordin de Plata' && !this.hasProforma)
                            this.extProforma = this.billingProviders[0].value;
                    }
                }
                this.billingProvidersLoaded = true;
            });
        },
        urlHartaLocker(lat, long) {
            return 'https://www.google.com/maps/search/?api=1&query=' + lat + '%2C' + long;
        },
    },
    computed: {
        listaJudete: get('sales/order@awb.counties'),
        maxProdutReturQty() {
            if (this.returDialog.model === '') return 0;
            const productIndex = this.orderProducts.findIndex((p) => p.products_model === this.returDialog.model);
            if (productIndex < 0) return 0;
            const returnedQty =
                parseInt(this.orderProducts[productIndex].qtyReturned) >
                parseInt(this.orderProducts[productIndex].returQty)
                    ? this.orderProducts[productIndex].qtyReturned
                    : this.orderProducts[productIndex].returQty;
            const returnQty = parseInt(this.orderProducts[productIndex].products_quantity) - parseInt(returnedQty);
            return returnQty < 0 ? 0 : returnQty;
        },
        hasProforma() {
            return (
                has(this.orderDetails, 'proforma_serie') &&
                this.orderDetails.proforma_serie !== '' &&
                has(this.orderDetails, 'proforma_numar') &&
                this.orderDetails.proforma_numar !== ''
            );
        },
        hasOrderContactMessages() {
            return this.orderContact.length != 0;
        },
        hasDeschidereColet() {
            return parseInt(this.taxaDeschidereColet) !== 0;
        },
        hasOrderDetails() {
            return !isEmpty(this.orderDetails);
        },
        billStatus() {
            return (has(this.orderDetails, 'nr_fact') && this.orderDetails.nr_fact !== '') || false;
        },
        paymentMethodComputed() {
            return this.orderDetails.payment_method !== '' && !isEmpty(this.orderDetails.payment_method)
                ? this.orderDetails.payment_method
                : false;
        },
        orderStatus() {
            return typeof this.statusList[this.orderDetails.status] !== 'undefined' &&
                typeof this.statusList[this.orderDetails.status] !== 'null'
                ? this.statusList[this.orderDetails.status]
                : false;
        },
        paymentMethodCheckType() {
            return this.paymentMethod ? 'success' : 'danger';
        },
        billStatusCheckType() {
            return this.billStatus ? 'success' : 'warning';
        },
        paymentStatusCheckType() {
            return this.orderSummary.paymentStatus ? 'success' : 'danger';
        },
        orderStatusCheckType() {
            return this.orderStatus ? 'success' : 'warning';
        },
        paymentMethodCheckText() {
            return this.paymentMethod ? this.paymentMethod : this.$t('sells.order.unknown');
        },
        billStatusCheckText() {
            return this.billStatus ? this.$t('sells.order.bill-set') : this.$t('sells.order.bill-notset');
        },
        paymentStatusCheckText() {
            if (typeof this.orderSummary.paymentStatus === 'string') {
                return this.$t('sells.order.payment-check-loading') + '...';
            }

            return this.orderSummary.paymentStatus
                ? this.$t('sells.order.payment-checked')
                : this.$t('sells.order.payment-notchecked');
        },
        confirmedPayment() {
            return this.orderSummary.paymentStatus;
        },
        orderStatusCheckText() {
            return this.orderStatus ? this.orderStatus : this.$t('sells.order.unknown');
        },
        orderProducts() {
            return this.orderDetails.products || [];
        },
        deleteActionTotalProducts() {
            let total_prod = 0;

            for (let i = 0; i < this.orderProducts.length; i++) {
                if (!this.productModelShouldSkip(this.orderProducts[i].products_model)) {
                    total_prod++;
                }
            }

            return total_prod;
        },
        clientName() {
            return decodeHTML(
                has(this.orderDetails, 'customers_company') && String(this.orderDetails.customers_company).trim() !== ''
                    ? this.orderDetails.customers_company
                    : this.$t('sells.order.unknown')
            );
        },
        customers_iban() {
            return has(this.orderDetails, 'customers_iban') && this.orderDetails.customers_iban !== ''
                ? this.orderDetails.customers_iban
                : '-';
        },
        clientAddress() {
            return (
                has(this.orderDetails, 'billing_info') &&
                has(this.orderDetails.billing_info, 'strada') &&
                ('string' != typeof this.orderDetails.billing_info.strada ||
                String(this.orderDetails.billing_info.strada).trim() !== ''
                    ? this.orderDetails.billing_info.strada
                    : this.$t('sells.order.unknown-2'))
            );
        },
        billingCompany() {
            return has(this.orderDetails, 'billing_info') &&
                has(this.orderDetails.billing_info, 'company') &&
                ('string' != typeof this.orderDetails.billing_info.company ||
                    String(this.orderDetails.billing_info.company).trim() !== '')
                ? this.orderDetails.billing_info.company
                : '';
        },
        billingCUI() {
            return has(this.orderDetails, 'billing_info') &&
                has(this.orderDetails.billing_info, 'cui') &&
                ('string' != typeof this.orderDetails.billing_info.cui ||
                    String(this.orderDetails.billing_info.cui).trim() !== '')
                ? this.orderDetails.billing_info.cui
                : '';
        },
        billingJ() {
            return has(this.orderDetails, 'billing_info') &&
                has(this.orderDetails.billing_info, 'j') &&
                ('string' != typeof this.orderDetails.billing_info.j ||
                    String(this.orderDetails.billing_info.j).trim() !== '')
                ? this.orderDetails.billing_info.j
                : '';
        },
        billingIBAN() {
            return has(this.orderDetails, 'customers_iban') && this.orderDetails.customers_iban !== ''
                ? this.orderDetails.customers_iban
                : '-';
        },
        billingBanca() {
            return has(this.orderDetails, 'customers_banca') && this.orderDetails.customers_banca !== ''
                ? this.orderDetails.customers_banca
                : '-';
        },
        clientCounty() {
            return has(this.orderDetails, 'billing_info') &&
                has(this.orderDetails.billing_info, 'suburbie') &&
                String(this.orderDetails.billing_info.suburbie).trim() !== ''
                ? this.orderDetails.billing_info.suburbie
                : this.$t('sells.order.unknown');
        },
        clientCity() {
            return has(this.orderDetails, 'billing_info') &&
                has(this.orderDetails.billing_info, 'oras') &&
                String(this.orderDetails.billing_info.judet).trim() !== ''
                ? this.orderDetails.billing_info.judet
                : this.$t('sells.order.unknown');
        },
        clientDeliveryName() {
            return decodeHTML(
                has(this.orderDetails, 'delivery_name') && String(this.orderDetails.delivery_name).trim() !== ''
                    ? this.orderDetails.delivery_name
                    : this.$t('sells.order.unknown-2')
            );
        },
        clientDeliveryAddress() {
            return has(this.orderDetails, 'delivery_info') &&
                has(this.orderDetails.delivery_info, 'strada') &&
                String(this.orderDetails.delivery_info.strada).trim() !== ''
                ? this.orderDetails.delivery_info.strada
                : this.$t('sells.order.unknown-2');
        },
        clientDeliveryCounty() {
            return has(this.orderDetails, 'delivery_info') &&
                has(this.orderDetails.delivery_info, 'judet') &&
                String(this.orderDetails.delivery_info.judet).trim() !== ''
                ? this.orderDetails.delivery_info.judet
                : this.$t('sells.order.unknown');
        },
        pickupName() {
            return decodeHTML(
                has(this.lockerData, 'name') && String(this.lockerData.name).trim() !== ''
                    ? this.lockerData.name
                    : this.$t('sells.order.unknown')
            );
        },
        pickupAdress() {
            return decodeHTML(
                has(this.lockerData, 'address') && String(this.lockerData.address).trim() !== ''
                    ? this.lockerData.address
                    : this.$t('sells.order.unknown-2')
            );
        },
        pickupTown() {
            return decodeHTML(
                has(this.lockerData, 'town') && String(this.lockerData.town).trim() !== ''
                    ? this.lockerData.town
                    : this.$t('sells.order.unknown')
            );
        },
        pickupRegion() {
            return decodeHTML(
                has(this.lockerData, 'region') && String(this.lockerData.region).trim() !== ''
                    ? this.lockerData.region
                    : this.$t('sells.order.unknown')
            );
        },
        clientDeliveryCity() {
            return has(this.orderDetails, 'delivery_info') &&
                has(this.orderDetails.delivery_info, 'oras') &&
                String(this.orderDetails.delivery_info.oras).trim() !== ''
                ? this.orderDetails.delivery_info.oras
                : this.$t('sells.order.unknown');
        },
        clientTelephone() {
            return has(this.orderDetails, 'customers_telephone')
                ? this.orderDetails.customers_telephone
                : this.$t('sells.order.phone-error');
        },
        invoiceHistory() {
            return has(this.orderDetails, 'all_invoices') && this.orderDetails.all_invoices.length > 0
                ? this.orderDetails.all_invoices
                : false;
        },
        canBeCanceled() {
            return parseInt(this.orderDetails.status) !== 11;
        },
        hasInvoice() {
            return (
                has(this.orderDetails, 'nr_fact') &&
                this.orderDetails.nr_fact !== '' &&
                has(this.orderDetails, 'serie') &&
                this.orderDetails.serie !== ''
            );
        },
        isConfirmed() {
            return parseInt(this.orderDetails.status) === 21;
        },
        isCanceled() {
            return parseInt(this.orderDetails.status) === 11;
        },
        isFinished() {
            return parseInt(this.orderDetails.status) === 25;
        },
        lockerTypeOrder() {
            return parseInt(this.orderDetails.pickup_locker) === 1;
        },
        canStorno() {
            return this.orderDetails.canStorno != null && this.orderDetails.canStorno == 1;
        },
        isStorno() {
            return parseInt(this.orderDetails.status) === 24;
        },
        canGenerateProforma() {
            return this.paymentMethod == 'Ordin de plata';
        },
        filteredPaymentMethods() {
            return this.paymentMethod.toLowerCase().includes('card') ? [this.paymentMethod] : this.paymentMethods;
        },
        commissionNotReturned() {
            return (
                this.orderDetails != null &&
                this.orderDetails.returnedCommission != null &&
                !this.orderDetails.returnedCommission.id_borderou &&
                this.orderDetails.returnedCommission.status != 1
            );
        },
        returStornoOrder() {
            let products = JSON.parse(JSON.stringify(this.orderProducts));
            products = products.filter(
                (product) =>
                    parseInt(products.products_id) !== 4500 &&
                    product.products_model !== 'taxa_transport' &&
                    product.products_model !== 'taxa_deschidere_colet' &&
                    (product.is_retur == 0 || parseInt(product.qtyReturned) < parseInt(product.products_quantity))
            );
            const totalUnreturnedProducts = products.length;

            if (
                totalUnreturnedProducts == 1 &&
                parseInt(products[0].products_quantity) == parseInt(this.returDialog.qty)
            ) {
                return true;
            } else {
                return false;
            }
        },
        conversationMessages() {
            return [];
        },
        hasOrderEmail() {
            return this.orderDetails.order_email != null;
        },
        orderEmail() {
            return this.orderDetails.order_email;
        },
        preselectedCourierId() {
            return this.lockerData?.courier_id != null ? this.lockerData.courier_id : 0;
        },
    },
    created() {
        this.createdFunction();
    },
    beforeCreate() {
        this.$store.registerModule('sales', createSalesStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('sales');
        EventBus.$off('replySent');
        EventBus.$off('invoiceEvent');
        EventBus.$off('billingProviderChanged');
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
@import './src/styles/modules/comanda.scss';
@import './src/styles/modules/comunicare.scss';

.middle-message {
    text-align: center;
}

.commission-return p {
    margin-block-start: 0em;
}

.elementPickupPointsSelect {
    max-height: 50vh;
    overflow-y: auto;
}

.elementPickupPointsSelect table {
    width: 100%;
}

.elementPickupPointsSelect table td {
    border-bottom: 1px solid #888;
}

.elementPickupPointsSelect table td:last-child {
    text-align: center;
}
</style>
